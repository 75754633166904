import { SET_ACTIVE_THIRD_PARTY_GAME_URL, SET_THIRD_PARTY_BET_RECORDS, SET_THIRD_PARTY_GAMES_OPTIONS, SET_THIRD_PARTY_GAME_AMOUNT, SET_TRANSACTION_DETAILS, SET_TRANSACTION_RECORDS } from '../../actionTypes';

const initialState = {
  thirdpartyBetRecords: undefined,
  thirdpartyGameOptions: undefined,
  thirdPartyGameUrl: undefined,
  transactionDetails: undefined,
  transactionRecords: undefined,
};

const thirdpartygamesReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_THIRD_PARTY_BET_RECORDS:
      return {
        ...state, thirdpartyBetRecords: action.value
      };

    case SET_THIRD_PARTY_GAMES_OPTIONS:
      return {
        ...state, thirdpartyGameOptions: action.value
      };

    case SET_ACTIVE_THIRD_PARTY_GAME_URL:
      return {
        ...state, thirdPartyGameUrl: action.value
      };

    case SET_THIRD_PARTY_GAME_AMOUNT:
      let { gamesList } = state.thirdpartyGameOptions || {};
      const { gameCode, amount } = action.value || {};
      gamesList = gamesList.map(item => {
        if (item.gameCode === gameCode) {
          item.amount = amount;
        }
        return item;
      });

      return {
        ...state,
        thirdpartyGameOptions: {
          ...state.thirdpartyGameOptions,
          gamesList
        }
      };
    case SET_TRANSACTION_DETAILS:
      return {
        ...state, transactionDetails: action.value
      };
    case SET_TRANSACTION_RECORDS:
      return {
        ...state, transactionRecords: action.value
      };
    default:
      return state;
  }
};

export default thirdpartygamesReducers;