import { StatusCodes } from 'http-status-codes';

import { SET_ACTIVE_THIRD_PARTY_GAME_URL, SET_MODAL_ALERT, SET_THIRD_PARTY_BET_RECORDS, SET_THIRD_PARTY_GAMES_OPTIONS, SET_THIRD_PARTY_GAME_AMOUNT, SET_TRANSACTION_DETAILS, SET_TRANSACTION_RECORDS } from '../../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import withdrawActions from '../withdrawActions';

const setThirdpartyBetRecords = topUpOptions => ({
  type: SET_THIRD_PARTY_BET_RECORDS,
  value: topUpOptions,
});

const setThirdPartyGamesOptions = gameOptions => ({
  type: SET_THIRD_PARTY_GAMES_OPTIONS,
  value: gameOptions
});

const setActiveThirdPartyGameURL = gameUrl => ({
  type: SET_ACTIVE_THIRD_PARTY_GAME_URL,
  value: gameUrl
});

const setThirdPartyGameAmountByCode = (gameCode, amount) => ({
  type: SET_THIRD_PARTY_GAME_AMOUNT,
  value: {
    gameCode,
    amount
  }
});

const setTransactionRecords = companyInfo => ({
  type: SET_TRANSACTION_RECORDS,
  value: companyInfo,
});

const setTransactionDetails = transactionDetails => ({
  type: SET_TRANSACTION_DETAILS,
  value: transactionDetails
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const betHistory = (beginTime = '', endTime = '', orderId = '', gameCode = '', page = 1, rows = 10, settleStatus = -1) => dispatch => {
  apiService.get('/thirdPartyGames/betHistory', `beginTime=${beginTime}&endTime=${endTime}&orderId=${orderId}&gameCode=${gameCode}&page=${page}&rows=${rows}&settleStatus=${settleStatus}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdpartyBetRecords(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const transfer = () => dispatch => {
  apiService.get('/thirdPartyGames/transfer/').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdPartyGamesOptions(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const autoGamesTransfer = (username, isMobile) => dispatch => {
  apiService.get(`/thirdPartyGames/autoGamesTransfer/${username}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const loadAmount = (gameCode, fromSite) => dispatch => {
  apiService.get(`/thirdPartyGames/loadAmount/${gameCode}`, `fromSite=${fromSite}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setThirdPartyGameAmountByCode(gameCode, data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getGameUrl = (gameCode, fromSite, params1 = '', params2 = '') => dispatch => {
  apiService.get(`/thirdPartyGames/GameUrl/${gameCode}`, `fromSite=${fromSite}&params1=${params1}&params2=${params2}`).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setActiveThirdPartyGameURL({
        url: data.result,
        gameCode
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const transferAmount = (fromCode, toCode, acAmount, fromSite, errorCallback, isMobile = false) => dispatch => {
  apiService.post('/thirdPartyGames/transferAmount', {
    fromCode,
    toCode,
    acAmount,
    fromSite
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    errorCallback(error.response);
    Promise.reject(error);
  });
};

const returnFunds = (username, isMobile = false, callback) => dispatch => {
  apiService.post(`/thirdPartyGames/returnFunds/${username}`).then(res => {
    const { data, status } = res || {};
    if (callback) {
      callback();
    }
    if (status === StatusCodes.OK) {
      dispatch(transfer());
      dispatch(withdrawActions.getInfo());
      if (isMobile) {
        dispatch(updateModalAlert({
          visible: true,
          type: 'success',
          data: {
            message: data.msg,
          }
        }));
      }
    }
  }).catch(error => {
    if (callback) {
      callback();
    }
    Promise.reject(error);
  });
};

const getTransactionRecords = (actionType, actionStatus, beginTime, endTime, orderId = '', page = 1, rows = 15) => dispatch => {
  apiService.get('/thirdPartyGames/allTransfers/', `actionType=${actionType}&actionStatus=${actionStatus}&beginTime=${beginTime}&endTime=${endTime}&orderId=${orderId}&page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setTransactionRecords(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const clearTransactionDetail = () => dispatch => {
  dispatch(setTransactionDetails(undefined));
  dispatch(setTransactionRecords(undefined));
};

const updateGameUrl = () => dispatch => {
  dispatch(setActiveThirdPartyGameURL());
};

const updateModalAlert = modal => dispatch => (
  dispatch(setModalAlert(modal))
);

export default {
  autoGamesTransfer,
  betHistory,
  loadAmount,
  returnFunds,
  transfer,
  transferAmount,
  getGameUrl,
  updateGameUrl,
  getTransactionRecords,
  clearTransactionDetail
};