import { StatusCodes } from 'http-status-codes';

import { SET_INIT_DATA, SET_MODAL_ALERT, SET_USER_GAMES } from '../../actionTypes';
import { apiService } from '../../../library';

import appActions from '../appActions';

const setUserGames = userGames => ({
  type: SET_USER_GAMES,
  value: userGames,
});

const setInitData = data => ({
  type: SET_INIT_DATA,
  value: data,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const getHomeIndex = () => dispatch => {
  apiService.get('/home/Init').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        return dispatch(setInitData(data.result));
      }
    }
  }).catch(error => {
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getUserGames = successCallback => dispatch => {
  apiService.get('/home/getInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      const lotteryList = (data.result?.lotteryList || []).sort((a, b) => b.popular - a.popular);
      const userGames = {
        ...data.result, lotteryList
      };
      dispatch(setUserGames(userGames));
      if (successCallback) successCallback(userGames);
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const LotteryNotice = lotteryType => dispatch => {
  apiService.get(`/home/LotteryNotice/${lotteryType}`).then(res => {
    const { data, status } = res || {};
    const { state, result } = data || {};

    if (status === StatusCodes.OK) { // 4010 and 4011 got notice
      if (state === 4010 || state === 4011) {
        dispatch(setModalAlert({
          visible: true,
          type: 'gameNotice',
          data: {
            ...result
          }
        }));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getUserGames,
  LotteryNotice,
  getHomeIndex,
};