import { useEffect } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, trendActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getInfo: lotteryType => dispatch(trendActions.getInfo(lotteryType)),
});

const mapStateToProps = state => ({
  lotteryType: state.gameReducers.lotteryType,
  changlongData: state.trendReducers.changlongData,
});

function MobileChangLongModal({ data, lotteryType, updateModalAlert, getInfo, changlongData }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (lotteryType && lotteryType?.item) {
      getInfo(lotteryType?.item);
    }
  }, [lotteryType]);

  return (
    <div className="mobileChangLongListModal">
      <Row className="modalHeader m-0">
        <Col className={data?.headerClassName}>
          两面长龙
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center mx-0">
        <div className="w-100 betListScrollContent px-1">
          {
            (changlongData && changlongData?.length > 0) ? changlongData?.map((item, index) => (
              <div className="d-flex py-2 border-bottom w-100" key={`changlongitem_${index.toString()}`}>
                <div className="col-8">{item?.projectName}</div>
                <div className="col-4">
                  {`${item?.count}期`}
                </div>
              </div>
            ))
              : <div className="w-100 h-100 d-flex justify-content-center align-items-center">暂无数据</div>
          }
        </div>
      </Row>
      <Row className="modalFooter align-items-center justify-content-center mx-0 py-3 px-2 flex-nowrap">
        <Button
          className="confirmButton py-2 w-100"
          onClick={() => updateModalAlert({
            visible: false
          })}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileChangLongModal);