import { StatusCodes } from 'http-status-codes';

import { SET_LOTTERY_RULES_DATA } from '../../actionTypes';
import { apiService } from '../../../library';

const setLotteryRules = lotteryRulesData => ({
  type: SET_LOTTERY_RULES_DATA,
  value: lotteryRulesData,
});

const getInfo = lotteryType => dispatch => {
  apiService.get(`/GameRule/GetInfo/${lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (data && data.result) {
        dispatch(setLotteryRules(data.result));
      }
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getInfo,
};