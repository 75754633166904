import { useEffect, useState } from 'react';

import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, memberActions } from '../../../redux/actions';
import ModalAlert from '../../../components/ModalAlert';
import './styles.scss';

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  captchaData: state.memberReducers.captchaData,
});

const mapDispatchToProps = dispatch => ({
  login: (loginData, callback) => dispatch(memberActions.login(loginData, callback)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getInfo: callback => dispatch(memberActions.getInfo(callback)),
});

function Login({ login, userData, history, captchaData, getCaptcha, updateModalAlert, getInfo }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCaptcha] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (userData) {
      if (userData?.checkPassword) {
        return;
      }
      getInfo(() => { history.push('/agreement'); });
    }
  }, [userData]);

  useEffect(() => {
    if (captchaData && captchaData.base64) {
      setCaptchaImage(`data:image/jpg;base64,${captchaData.base64}`);
    } else {
      getCaptcha();
    }
  }, [captchaData]);

  const onCaptchaClick = () => {
    getCaptcha();
  };

  const onLoginClick = () => {
    const loginData = {
      username,
      code,
      password
    };
    login(loginData, data => {
      if (data?.checkPassword) {
        return updateModalAlert({
          visible: true,
          type: 'changePassword',
        });
      }
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [username, code, password]);

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onLoginClick();
    }
  }

  return (
    <div className={`loginRegisterWarpper theme-${localStorage.getItem('themeColor') || 'blue'}`}>
      <div className={`loginAndRegister ${localStorage.getItem('themeColor') || 'blue'}`}>
        <div className="loginBox">
          <div>
            <div className="loginTitle">{t('EMAIL_LOGIN')}</div>
            <div className="loginBoxItem">
              <div>{t('ACCOUNT')}</div>
              <Input value={username} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setUsername(text)} />
            </div>
            <div className="loginBoxItem">
              <div>{`${t('PASSWORD')}`}</div>
              <Input type="password" placeholder="请输入您的密码" value={password} onChange={({ target: { value: text } }) => setPassword(text)} />
            </div>
            <div className="loginBoxItem">
              <div>{`${t('VERIFY_CODE')}`}</div>
              <Input placeholder="请输入验证码" value={code} onChange={({ target: { value: text } }) => setCaptcha(text)} />
              <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
                <img id="captchaImg" src={captchaImage} alt="" className="w-100" />
              </div>
            </div>
          </div>
          <div className="loginBoxItem">
            <Button className="loginButton" onClick={() => onLoginClick()}>{t('LOGIN')}</Button>
          </div>
        </div>
        <ModalAlert />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);