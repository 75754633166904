import { useState } from 'react';

import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DatePicker from 'react-mobile-datepicker';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function DatePickerModal({ updateModalAlert, dates }) {
  const { t } = useTranslation();
  const [tempSelectDate, setTempSelectDate] = useState((dates && moment(dates.selected).toDate()) || new Date());
  const dateConfig = {
    year: {
      format: 'YYYY年',
      caption: 'Year',
      step: 1,
    },
    month: {
      format: 'MM月',
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'DD日',
      caption: 'Day',
      step: 1,
    },
  };
  return (
    <div className="datepickerWrapper">
      <ModalHeader className="px-0 text-white">
        {t('PLEASE_SELECT_DATE')}
      </ModalHeader>
      <ModalBody className="datepickerModal">
        <DatePicker
          isPopup={false}
          value={tempSelectDate}
          isOpen
          onSelect={() => updateModalAlert({
            visible: false
          })}
          showFooter={false}
          showHeader={false}
          onCancel={() => updateModalAlert({
            visible: false
          })}
          dateConfig={dateConfig}
          theme="ios"
          min={dates && dates.min}
          max={dates && dates.max}
          onChange={value => setTempSelectDate(value)}
        />
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col xs="6">
            <Button
              type="submit"
              className="button"
              size="md"
              block
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
              }}
            >
              {t('CANCEL')}
            </Button>
          </Col>
          <Col xs="6">
            <Button
              type="submit"
              className="button"
              size="md"
              block
              onClick={() => {
                if (dates.onConfirm) dates.onConfirm(tempSelectDate);
                updateModalAlert({
                  visible: false
                });
              }}
            >
              {t('CONFIRM')}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(DatePickerModal);