import { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

import { appActions, thirdpartygamesActions } from '../../../redux/actions';
import Label from '../../Label';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  thirdPartyGameUrl: state.thirdpartygamesReducers.thirdPartyGameUrl,
  thirdpartyGameOptions: state.thirdpartygamesReducers.thirdpartyGameOptions,
  platform: state.appReducers.platform,
  userData: state.memberReducers.userData,
});

const mapDispatchToProps = dispatch => ({
  transfer: () => dispatch(thirdpartygamesActions.transfer()),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  transferAmount: (fromCode, toCode, acAmount, fromSite) => dispatch(thirdpartygamesActions.transferAmount(fromCode, toCode, acAmount, fromSite)),
  loadAmount: (gameCode, fromSite) => dispatch(thirdpartygamesActions.loadAmount(gameCode, fromSite)),
  returnFunds: username => dispatch(thirdpartygamesActions.returnFunds(username)),
  updateGameUrl: () => dispatch(thirdpartygamesActions.updateGameUrl()),
});

const { Option } = Select;

function ThirdPartyModal({ data, transfer, platform, updateModalAlert, thirdpartyGameOptions, transferAmount, loadAmount, returnFunds, userData, thirdPartyGameUrl, updateGameUrl }) {
  const { t } = useTranslation();
  const [_selectedLeft, setSelectedLeft] = useState();
  const [_selectedRight, setSelectedRight] = useState();
  const [_thirdpartyGameOptions, setThirdpartyGameOptions] = useState();
  const [_mainWallet, setMainWallet] = useState();
  const [_transferAmount, setTransferAmount] = useState();
  const [_transferAmountError, setTransferAmountError] = useState();
  const history = useLocation();

  useEffect(() => {
    transfer();
    return () => updateGameUrl();
  }, []);

  useEffect(() => {
    if (JSON.stringify(thirdpartyGameOptions) !== JSON.stringify(_thirdpartyGameOptions)) {
      setThirdpartyGameOptions(thirdpartyGameOptions);
      setMainWallet(thirdpartyGameOptions.gamesList.find(x => x.gameCode === 'MAIN'));
      setSelectedLeft(thirdpartyGameOptions.gamesList.find(x => x.gameCode === 'MAIN'));
      setSelectedRight(thirdpartyGameOptions.gamesList.find(x => x.gameCode === data.gameCode));
    }
  }, [thirdpartyGameOptions]);

  const onTransferClick = () => {
    if (!_transferAmount) {
      return setTransferAmountError(t('PLEASE_ENTER_VALID_AMOUNT'));
    }

    if (!_selectedLeft) {
      return setTransferAmountError(t('PLEASE_SELECT_TRANSFER_IN_ACCOUNT'));
    }

    if (!_selectedRight) {
      return setTransferAmountError(t('PLEASE_SELECT_TRANSFER_OUT_ACCOUNT'));
    }

    setTransferAmount();
    return transferAmount(_selectedLeft.gameCode, _selectedRight.gameCode, _transferAmount, 'WEB-PC');
  };

  return (
    <div className={`thirdPartyPanelWrapper${platform}`}>
      <Row className={`modalHeader ${platform === 'Mobile' && 'm-0'}`}>
        <Col
          className={`${platform === 'Web' && 'd-flex justify-content-center align-items-center'}`}
          xs={platform === 'Web' && {
            size: 4, offset: 4
          }}
        >
          {t('TRANSFER_BALANCE')}
        </Col>
        <Col xs={platform === 'Web' && '4'} className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className={`modalContent ${platform === 'Mobile' && 'd-flex justify-content-center'}`}>
        <Col className="thirdPartyFormWrapper">
          <Row>
            <Col xs="1" className="d-flex justify-content-center align-items-center p-0">
              <Label value={t('TRANSFER_OUT')} />
            </Col>
            <Col xs="4" className="d-flex justify-content-center align-items-center">
              <Select
                key={`_selectedLeft_${_thirdpartyGameOptions}`}
                placeholder={_thirdpartyGameOptions ? <span>请选择</span> : <span>加載中...</span>}
                value={_selectedLeft}
                inputProps={{
                  'aria-label': 'Without label'
                }}
                onChange={value => {
                  if (JSON.stringify(value) !== JSON.stringify(_mainWallet)) {
                    setSelectedRight(_mainWallet);
                  }
                  return setSelectedLeft(value);
                }}
              >
                {
                  _thirdpartyGameOptions && _thirdpartyGameOptions.gamesList.filter(x => JSON.stringify(x) !== JSON.stringify(_selectedRight)).map(item => (
                    <Option key={item.gameName} value={item}>{item.gameName}</Option>
                  ))
                }
              </Select>
            </Col>
            <Col xs="2" className="p-0 flex-column d-flex">
              <Label className="amountText" value={t('AMOUNT')} />
              <Label className="amountText" value={`￥${(_selectedLeft && _selectedLeft.amount) || 0}`} />
            </Col>
            <Col xs="5" className="flex-row d-flex align-items-center p-0">
              <Col xs="4" className="p-0 d-flex justify-content-center">
                <Button outline className="refreshButton" onClick={() => { if (_selectedLeft) { loadAmount(_selectedLeft.gameCode, 'WEB-PC'); } }}>{t('REFRESH')}</Button>
              </Col>
              <Col xs="4" className="p-0 d-flex justify-content-center">
                <Button outline className="recycleButton" onClick={() => returnFunds(userData.loginId)}>{t('ONE_STEP_RECYCLE')}</Button>
              </Col>
              <Col xs="4" className="p-0 d-flex justify-content-center">
                <Button
                  className="topUpButton"
                  onClick={() => {
                    updateModalAlert({
                      visible: false
                    });
                    history.push({
                      pathname: '/user/bank',
                      state: {
                        type: 'topUp'
                      }
                    });
                  }}
                >
                  {t('TOP_UP')}
                </Button>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="d-flex justify-content-center align-items-center p-0">
              <Label value={t('TRANSFER_IN')} />
            </Col>
            <Col xs="4" className="d-flex justify-content-center align-items-center">
              <Select
                key={`_selectedRight_${_thirdpartyGameOptions}`}
                placeholder={_thirdpartyGameOptions ? <span>请选择</span> : <span>加載中...</span>}
                value={_selectedRight}
                onChange={value => {
                  if (JSON.stringify(value) !== JSON.stringify(_mainWallet)) {
                    setSelectedLeft(_mainWallet);
                  }
                  return setSelectedRight(value);
                }}
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label'
                }}
              >
                {
                  _thirdpartyGameOptions && _thirdpartyGameOptions.gamesList.filter(x => JSON.stringify(x) !== JSON.stringify(_selectedLeft)).map(item => (
                    <Option key={item.gameName} value={item}>
                      {item.gameName}
                    </Option>
                  ))
                }
              </Select>
            </Col>
            <Col xs="2" className="p-0 flex-column d-flex">
              <Label className="amountText" value={t('AMOUNT')} />
              <Label className="amountText" value={`￥${(_selectedRight && _selectedRight.amount) || 0}`} />
            </Col>
            <Col xs="5" className="flex-row d-flex align-items-center p-0">
              <Col xs="4" className="p-0 d-flex justify-content-center">
                <Button outline className="refreshButton" onClick={() => { if (_selectedRight) { loadAmount(_selectedRight.gameCode, 'WEB-PC'); } }}>{t('REFRESH')}</Button>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="d-flex justify-content-center align-items-center p-0">
              <Label value={t('AMOUNT')} />
            </Col>
            <Col xs="4">
              <Input
                value={_transferAmount}
                onChange={({ target: { value: text } }) => setTransferAmount(validateNumber(text))}
                placeholder={t('INPUT_TRANSFER_AMOUNT')}
                className="inputMoney"
              />
            </Col>
            <Col xs="2" className="p-0">
              <Button className="transferInButton" onClick={onTransferClick}>{t('TRANSFER_IN')}</Button>
            </Col>
            {
              _transferAmountError && (
                <Col xs="4" className="d-flex align-items-center">
                  <Label className="errorMessage ml-3" value={_transferAmountError} />
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
      <Row className={`modalFooter d-flex align-items-center ${platform === 'Mobile' && 'justify-content-center'}`}>
        <Button
          className="confirmButton my-3"
          onClick={() => {
            window.open(thirdPartyGameUrl.url, '_blank');
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('ENTER_GAME')}
        </Button>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyModal);