import { StatusCodes } from 'http-status-codes';

import { SET_LATEST_ANNOUNCEMENT, SET_NEWS_LIST } from '../../actionTypes';
import { apiService } from '../../../library';

const setNewsList = newsList => ({
  type: SET_NEWS_LIST,
  value: newsList,
});

const setLatestAnnouncement = announcementList => ({
  type: SET_LATEST_ANNOUNCEMENT,
  value: announcementList,
});

const getAnnouncement = (page = 1, rows = 15) => dispatch => {
  apiService.get('/notices/getAnnouncement', `page=${page}&rows=${rows}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setNewsList(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const latestAnnouncement = () => dispatch => {
  apiService.get('/notices/latestAnnouncement').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setLatestAnnouncement(data.result?.news || []));
    }
  }).catch(error => {
    setLatestAnnouncement([]);
    Promise.reject(error);
  });
};

export default {
  getAnnouncement,
  latestAnnouncement,
};