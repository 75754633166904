import { useState } from 'react';

import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function MobileNotificationModal({ updateModalAlert, data = [] }) {
  const [_doNotRemind, setDoNotRemind] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="mobileNotificationModalWrapper">
      <div className="modalHeader pl-3">
        最新公告
      </div>

      <div className="modalContent">
        <div className="content">
          {data.map(item => (
            <div key={item?.id} className="messageItem">
              <div>
                {moment.unix(item?.createDate ?? 0).format('YYYY-MM-DD HH:mm')}
              </div>
              <div>
                {item?.content}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="modalFooter text-center p-3">
        <div className="d-flex justify-content-center align-items-center">
          <span
            className="promotionButton w-50 py-2 text-white"
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond}; Path=/`;
              }
              history.push('/user/announcement');
              updateModalAlert({
                visible: false
              });
            }}
          >
            {t('CHECK_OUT_DETAIL')}
          </span>
          <span
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond}; Path=/`;
              }
              updateModalAlert({
                visible: false
              });
            }}
            className="ml-3 py-2 confirmButton w-50"
          >
            {t('MODAL_ACKNOWLEDGE')}
          </span>
        </div>
        <div className="mt-2">
          <div className="d-flex justify-content-center align-items-center ">
            <Checkbox value={_doNotRemind} onChange={e => setDoNotRemind(e.target.checked)}>{t('DO_NOT_REMIND_TODAY')}</Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(MobileNotificationModal);