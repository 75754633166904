const GuaMa = {
  PK10: [
    '距离最新期开奖时间还有10秒后生成随机挂码，结果取自168开奖网，保证开奖是公平公正；',
    '开奖结果，与随机挂码相加，和值小于10时，以和值为开奖结果，大于10时，则取和值个位数为开奖结果，等于10时开奖结果为10 ；',
    '开出 ：4,9,10,3,1,5,2,7,8,6 ;挂码：2；',
    ' 当前期开奖结果为：6,1,2,5,3,7,4,9,10,8；'
  ],
  SSC: [
    '距离最新期开奖时间还有10秒后生成随机挂码，结果取自168开奖网，保证开奖是公平公正；',
    '开奖结果，与随机挂码相加，和值小于10时，以和值为开奖结果，大于10时，则取和值个位数为开奖结果，等于10时开奖结果为10 ；',
    '开出 ：4,9,10,3,1,5,2,7,8,6 ;挂码：2；',
    '当前期开奖结果为：6,1,2,5,3,7,4,9,10,8；'
  ]
};

export default GuaMa;