import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Cross } from '../../../assets/icon/TopUpIcons/icon_cross.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function ErrorModal({ platform, updateModalAlert, data }) {
  const { t } = useTranslation();
  return (
    <div className={`errorModalWrapper${platform}`}>
      <div className="d-flex modalHeader mx-0">
        <div className="col-6 d-flex align-items-center">
          {t('REMINDER')}
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              if (data && data.onConfirmationClick) data.onConfirmationClick();
              updateModalAlert({
                visible: false
              });
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </div>
      </div>
      <div className="modalContent d-flex justify-content-center flex-column mx-0">
        <div className={`mx-0 d-flex justify-content-center align-items-center ${platform === 'Web' ? 'mb-4 mt-5' : 'my-2'}`}>
          <Cross className="errorCross" />
        </div>
        <div className={`d-flex justify-content-center ${platform === 'Web' && 'mb-4'}`}>
          <Label className="errorText" value={data && data.message} />
        </div>
      </div>
      <div className="modalFooter d-flex align-items-center justify-content-center mx-0">
        <Button
          className="confirmButton my-2"
          onClick={() => {
            if (data && data.onConfirmationClick) data.onConfirmationClick();
            updateModalAlert({
              visible: false
            });
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);