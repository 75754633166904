import moment from 'moment';
import 'moment/locale/zh-cn';

const validateNumber = (text, escapeCharacter = [], skipTrim = true, returnType = 'number') => {
  if (!text) return undefined;
  let tempText = skipTrim ? text : text.trim();
  if ((Number.isNaN(Number(text)) || text.includes('.')) && !escapeCharacter.includes(text)) {
    tempText = tempText.split('').map(x => ((Number.isNaN(Number(x)) || x === '.') && !escapeCharacter.includes(x) ? '' : x)).join('');
  }
  return !Number.isNaN(Number(text)) && returnType === 'number' ? parseInt(tempText, 10) : tempText;
};

const validateQxcNumber = (text, type) => {
  if (type === 'number') {
    const regex = /^[\d*]*$/;
    return typeof text === 'string' && regex.test(text);
  }
  if (type === 'amount') {
    if (text === '') return true;
    const regex = /^\d+$/;
    return typeof text === 'string' && regex.test(text);
  }
  return false;
};

const renderHeXiaoBalls = (preZodiac = '猪', currentZodiac, zodiacTotalBall = 48) => {
  const REDBALL = ['1', '2', '7', '8', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['3', '4', '9', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['5', '6', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  const zodiacList = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];
  const result = [];
  const startIndex = zodiacList.indexOf(preZodiac) - zodiacList.indexOf(currentZodiac) + 1;
  for (let i = startIndex; i <= zodiacTotalBall; i += 12) {
    let ballStyle = '';
    if (i > 0) {
      if (REDBALL.includes(`${i}`)) {
        ballStyle = 'circle circleRed';
      } else if (BLUEBALL.includes(`${i}`)) {
        ballStyle = 'circle circleBlue';
      } else if (GREENBALL.includes(`${i}`)) {
        ballStyle = 'circle circleGreen';
      }
      result.push({
        value: i, className: `m-auto ${ballStyle}`
      });
    }
  }
  return result;
};

const renderSeBoBalls = value => {
  const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
  const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
  const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
  if (value === '红波') {
    return REDBALL.map(item => ({
      value: item, className: 'circle circleRed'
    }));
  }
  if (value === '蓝波') {
    return BLUEBALL.map(item => ({
      value: item, className: 'circle circleBlue'
    }));
  }
  if (value === '绿波') {
    return GREENBALL.map(item => ({
      value: item, className: 'circle circleGreen'
    }));
  }
};
const arrWxNo = [{
  begindate: '2021-02-12',
  enddate: '2022-01-31',
  金: '07,08,21,22,29,30,37,38',
  木: '03,04,11,12,19,20,33,34,41,42,49',
  水: '09,10,17,18,25,26,39,40,47,48',
  火: '05,06,13,14,27,28,35,36,43,44',
  土: '01,02,15,16,23,24,31,32,45,46'
},
{
  begindate: '2022-02-01',
  enddate: '2023-01-21',
  金: '01,08,09,22,23,30,31,38,39',
  木: '04,05,12,13,20,21,34,35,42,43',
  水: '10,11,18,19,26,27,40,41,48,49',
  火: '06,07,14,15,28,29,36,37,44,45',
  土: '02,03,16,17,24,25,32,33,46,47'
},
{
  begindate: '2023-01-22',
  enddate: '2025-01-28',
  金: '02,03,10,11,24,25,32,33,40,41',
  木: '06,07,14,15,22,23,36,37,44,45',
  水: '12,13,20,21,28,29,42,43',
  火: '01,08,09,16,17,30,31,38,39,46,47',
  土: '04,05,18,19,26,27,34,35,48,49'
}];
const REDBALL = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'];
const BLUEBALL = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'];
const GREENBALL = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49'];
const renderWuXingBalls = value => {
  const result = [];
  const currentWX = arrWxNo.find(x => new Date() >= new Date(x.begindate) && new Date() <= new Date(x.enddate));
  if (currentWX) {
    currentWX[`${value}`].split(',').forEach(item => {
      if (REDBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleRed'
        });
      }
      if (BLUEBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleBlue'
        });
      }
      if (GREENBALL.includes(item)) {
        result.push({
          value: item, className: 'circle circleGreen'
        });
      }
    });
  }
  return result;
};
const renderWSBalls = value => {
  const result = [];
  REDBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleRed'
      });
    }
  });
  BLUEBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleBlue'
      });
    }
  });
  GREENBALL.forEach(item => {
    if (item.slice(-1) === value.slice(0, 1)) {
      result.push({
        value: item, className: 'circle circleGreen'
      });
    }
  });
  return result.sort((a, b) => a.value - b.value);
};

const renderResultDateCell = (data, language) => {
  moment.locale(language === 'zh' ? 'zh-cn' : 'en');
  const date = moment(data.lotteryTime).format('YYYY-MM-DD');
  const time = `(${moment.weekdays(moment(data.lotteryTime).days())}) ${moment(data.lotteryTime).format('HH:mm')}`;
  return [{
    value: date,
    className: 'd-flex align-items-center justify-content-center'
  }, {
    value: time,
    className: 'd-flex align-items-center justify-content-center'
  }];
};

const inviteCodeValidation = args => {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(args);
};

const quickBetDictionary = {
  红波: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46],
  蓝波: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿波: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49],
  红大: [29, 30, 34, 35, 40, 45, 46],
  蓝大: [25, 26, 31, 36, 37, 41, 42, 47, 48],
  绿大: [27, 28, 32, 33, 38, 39, 43, 44, 49],
  红小: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
  蓝小: [3, 4, 9, 10, 14, 15, 20],
  绿小: [5, 6, 11, 16, 17, 21, 22],
  红单: [1, 7, 13, 19, 23, 29, 35, 45],
  蓝单: [3, 9, 15, 25, 31, 37, 41, 47],
  绿单: [5, 11, 17, 21, 27, 33, 39, 43, 49],
  红双: [2, 8, 12, 18, 24, 30, 34, 40, 46],
  蓝双: [4, 10, 14, 20, 26, 36, 42, 48],
  绿双: [6, 16, 22, 28, 32, 38, 44],
  大号: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
  小号: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  单号: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  双号: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  大单: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49],
  大双: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  小单: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
  小双: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  合单: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49],
  合双: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48],
};

const CONST_ZODIACS = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪'];

const CONST_NUMS = [
  '01,13,25,37,49', '02,14,26,38', '03,15,27,39',
  '04,16,28,40', '05,17,29,41', '06,18,30,42',
  '07,19,31,43', '08,20,32,44', '09,21,33,45',
  '10,22,34,46', '11,23,35,47', '12,24,36,48'
];

function sort(z) {
  const befor = [];
  const after = [];
  let point = 0;// 当前生肖实际位置
  for (let i = 0; i < CONST_ZODIACS.length; i++) {
    if (z === CONST_ZODIACS[i]) {
      point = i;
      befor.push(z);
      break;
    }
  }
  for (let j = CONST_ZODIACS.length - 1; j >= 0; j--) {
    if (j > point) { // 后面
      after.push(CONST_ZODIACS[j]);
    }
    if (j < point) { // 前面
      befor.push(CONST_ZODIACS[j]);
    }
  }
  let result = [];
  result = result.concat(befor);
  result = result.concat(after);
  return result;
}


function getNumByAnimal(animal, targanimal) {
  const newzodiacs = sort(animal);
  let point = 0;// 排序后的位置
  let s = 0;
  for (s = 0; s < newzodiacs.length; s++) {
    const sz = newzodiacs[s];
    if (sz === targanimal) {
      break;
    }
    point += 1;
  }
  if (s <= newzodiacs.length) return CONST_NUMS[point];
  return '';
}

function generateListForDW1(selectedValue) {
  const list = [];
  for (let i = 0; i < 10; i++) {
    const formattedNumber = i.toString();
    let code;
    let displayName;

    switch (selectedValue) {
      case '口XXX':
        code = `DW1_${formattedNumber}XXX`;
        displayName = `${formattedNumber}XXX`;
        break;
      case 'X口XX':
        code = `DW1_X${formattedNumber}XX`;
        displayName = `X${formattedNumber}XX`;
        break;
      case 'XX口X':
        code = `DW1_XX${formattedNumber}X`;
        displayName = `XX${formattedNumber}X`;
        break;
      case 'XXX口':
        code = `DW1_XXX${formattedNumber}`;
        displayName = `XXX${formattedNumber}`;
        break;
      default:
        code = `DW1_${formattedNumber}XXX`;
        displayName = `${formattedNumber}XXX`;
        break;
    }
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForDW2(selectedValue) {
  const list = [];
  for (let i = 0; i < 100; i++) {
    const formattedNumber = i.toString().padStart(2, '0');
    let code;
    let displayName;

    switch (selectedValue) {
      case '口口XX':
        code = `DW2_${formattedNumber}XX`;
        displayName = `${formattedNumber}XX`;
        break;
      case '口X口X':
        code = `DW2_${formattedNumber[0]}X${formattedNumber[1]}X`;
        displayName = `${formattedNumber[0]}X${formattedNumber[1]}X`;
        break;
      case '口XX口':
        code = `DW2_${formattedNumber[0]}XX${formattedNumber[1]}`;
        displayName = `${formattedNumber[0]}XX${formattedNumber[1]}`;
        break;
      case 'X口口X':
        code = `DW2_X${formattedNumber}X`;
        displayName = `X${formattedNumber}X`;
        break;
      case 'X口X口':
        code = `DW2_X${formattedNumber[0]}X${formattedNumber[1]}`;
        displayName = `X${formattedNumber[0]}X${formattedNumber[1]}`;
        break;
      case 'XX口口':
        code = `DW2_XX${formattedNumber}`;
        displayName = `XX${formattedNumber}`;
        break;
      default:
        code = `DW2_${formattedNumber}XX`;
        displayName = `${formattedNumber}XX`;
        break;
    }
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForDW3(selectedValue, qxcNumberSelection) {
  const list = [];
  const [startStr, endStr] = qxcNumberSelection.split('-');
  const start = parseInt(startStr, 10);
  const end = parseInt(endStr, 10);

  for (let i = start; i <= end; i++) {
    const formattedNumber = i.toString().padStart(3, '0');
    const formattedNumberDW4 = i.toString().padStart(4, '0');
    let code;
    let displayName;

    switch (selectedValue) {
      case '口口口X':
        code = `DW3_${formattedNumber}X`;
        displayName = `${formattedNumber}X`;
        break;
      case '口口X口':
        code = `DW3_${formattedNumber[0]}${formattedNumber[1]}X${formattedNumber[2]}`;
        displayName = `${formattedNumber[0]}${formattedNumber[1]}X${formattedNumber[2]}`;
        break;
      case '口X口口':
        code = `DW3_${formattedNumber[0]}X${formattedNumber[1]}${formattedNumber[2]}`;
        displayName = `${formattedNumber[0]}X${formattedNumber[1]}${formattedNumber[2]}`;
        break;
      case 'X口口口':
        code = `DW3_X${formattedNumber}`;
        displayName = `X${formattedNumber}`;
        break;
      case '口口口口':
        code = `DW4_${formattedNumberDW4}`;
        displayName = `${formattedNumberDW4}`;
        break;
      default:
        code = `DW3_${formattedNumber}X`;
        displayName = `${formattedNumber}X`;
        break;
    }
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForDW4(selectedValue, firstDigitValue, secondDigitValue) {
  const list = [];
  const cleanedSecondDigitValue = secondDigitValue.replace('a', '');
  for (let i = 0; i < 100; i++) {
    const formattedNumber = i.toString().padStart(2, '0');
    let code;
    let displayName;

    switch (selectedValue) {
      case '口口XX':
        code = `DW4_${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber}`;
        displayName = `${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber}`;
        break;
      case '口X口X':
        code = `DW4_${firstDigitValue}${formattedNumber[0]}${cleanedSecondDigitValue}${formattedNumber[1]}`;
        displayName = `${firstDigitValue}${formattedNumber[0]}${cleanedSecondDigitValue}${formattedNumber[1]}`;
        break;
      case '口XX口':
        code = `DW4_${firstDigitValue}${formattedNumber}${cleanedSecondDigitValue}`;
        displayName = `${firstDigitValue}${formattedNumber}${cleanedSecondDigitValue}`;
        break;
      case 'X口口X':
        code = `DW4_${formattedNumber[0]}${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber[1]}`;
        displayName = `${formattedNumber[0]}${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber[1]}`;
        break;
      case 'X口X口':
        code = `DW4_${formattedNumber[0]}${firstDigitValue}${formattedNumber[1]}${cleanedSecondDigitValue}`;
        displayName = `${formattedNumber[0]}${firstDigitValue}${formattedNumber[1]}${cleanedSecondDigitValue}`;
        break;
      case 'XX口口':
        code = `DW4_${formattedNumber}${firstDigitValue}${cleanedSecondDigitValue}`;
        displayName = `${formattedNumber}${firstDigitValue}${cleanedSecondDigitValue}`;
        break;
      default:
        code = `DW4_${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber}`;
        displayName = `${firstDigitValue}${cleanedSecondDigitValue}${formattedNumber}`;
        break;
    }
    list.push({
      code, displayName
    });
  }
  return list;
}

const generateCode = (inputNumber, format) => {
  const displayName = inputNumber.replace(/\*/g, 'X');
  let code;

  if (format === 'DW') {
    if (/^\d{4}$/.test(displayName)) {
      code = `DW4_${displayName}`;
    } else if (/^\d*X\d*$/.test(displayName) && (displayName.match(/X/g) || []).length === 1) {
      code = `DW3_${displayName}`;
    } else if (/^\d*X\d*X\d*$/.test(displayName) && (displayName.match(/X/g) || []).length === 2) {
      code = `DW2_${displayName}`;
    } else if (/^\d*X\d*X\d*X\d*$/.test(displayName) && (displayName.match(/X/g) || []).length === 3) {
      code = `DW1_${displayName}`;
    } else {
      code = displayName;
    }
  } else if (format === 'Z') {
    if (/^\d{4}$/.test(displayName)) {
      code = `Z4_${displayName}`;
    } else if (/^\d{3}$/.test(displayName)) {
      code = `Z3_${displayName}`;
    } else if (/^\d{2}$/.test(displayName)) {
      code = `Z2_${displayName}`;
    } else if (/^\d{1}$/.test(displayName)) {
      code = `Z1_${displayName}`;
    } else {
      code = displayName;
    }
  }

  return {
    displayName, code
  };
};

function isValidNumber(numInt, categoryValue) {
  switch (categoryValue) {
    case '二字':
      return numInt <= 100;
    case '三字':
      return numInt <= 1000;
    case '四字':
      return numInt <= 10000;
    case '三字0~234':
      return numInt <= 234;
    case '三字235~999':
      return numInt >= 235;
    case '四字0~355':
      return numInt <= 355;
    case '四字356~1289':
      return numInt >= 356 && numInt <= 1289;
    case '四字1299~2444':
      return numInt >= 1299 && numInt <= 2444;
    case '四字2445~4459':
      return numInt >= 2445 && numInt <= 4459;
    case '四字4466~9999':
      return numInt >= 4466;
    default:
      return false;
  }
}

const generateNumbersForZ4 = categoryValue => {
  const result = [];
  for (let i = 0; i < 10; i++) {
    for (let j = i; j < 10; j++) {
      for (let n = j; n < 10; n++) {
        for (let m = n; m < 10; m++) {
          const no = `${i}${j}${n}${m}`;
          const numInt = parseInt(no, 10);
          if (isValidNumber(numInt, categoryValue)) {
            result.push({
              code: `Z4_${no}`,
              displayName: no,
            });
          }
        }
      }
    }
  }
  return result;
};

const generateNumbersForZ3 = categoryValue => {
  const result = [];
  for (let i = 0; i < 10; i++) {
    for (let j = i; j < 10; j++) {
      for (let n = j; n < 10; n++) {
        const no = `${i}${j}${n}`;
        const numInt = parseInt(no, 10);
        if (isValidNumber(numInt, categoryValue)) {
          result.push({
            code: `Z3_${no}`,
            displayName: no,
          });
        }
      }
    }
  }
  return result;
};

const generateNumbersForZ2 = categoryValue => {
  const result = [];
  for (let i = 0; i < 10; i++) {
    for (let j = i; j < 10; j++) {
      const no = `${i}${j}`;
      const numInt = parseInt(no, 10);
      if (isValidNumber(numInt, categoryValue)) {
        result.push({
          code: `Z2_${no}`,
          displayName: no,
        });
      }
    }
  }
  return result;
};

const getVisibilityClass = (index, categoryValue) => {
  switch (categoryValue) {
    case '口XXX':
      return index === 0 ? '' : 'invisible';
    case 'X口XX':
      return index === 1 ? '' : 'invisible';
    case 'XX口X':
      return index === 2 ? '' : 'invisible';
    case 'XXX口':
      return index === 3 ? '' : 'invisible';
    case '口口XX':
      return index <= 1 ? '' : 'invisible';
    case '口X口X':
      return index === 0 || index === 2 ? '' : 'invisible';
    case '口XX口':
      return index === 0 || index === 3 ? '' : 'invisible';
    case 'X口口X':
      return index === 1 || index === 2 ? '' : 'invisible';
    case 'X口X口':
      return index === 1 || index === 3 ? '' : 'invisible';
    case 'XX口口':
      return index >= 2 ? '' : 'invisible';
    case '口口口X':
      return index <= 2 ? '' : 'invisible';
    case '口口X口':
      return index === 0 || index === 1 || index === 3 ? '' : 'invisible';
    case '口X口口':
      return index === 0 || index === 2 || index === 3 ? '' : 'invisible';
    case 'X口口口':
      return index >= 1 ? '' : 'invisible';
    case '口口口口':
      return '';
    case '一字':
      return index === 0 ? '' : 'invisible';
    case '二字':
      return index <= 1 ? '' : 'invisible';
    case '三字':
      return index <= 2 ? '' : 'invisible';
    case '四字':
      return '';
    default:
      return '';
  }
};

function generateListForPl3YZZH() {
  const list = [];
  for (let i = 0; i < 10; i++) {
    const code = `ZH1_${i}`;
    const displayName = `${i}`;
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForPl3EZZH() {
  const list = [];
  for (let i = 0; i < 10; i++) {
    for (let j = i; j < 10; j++) {
      const no = `${i}${j}`;
      list.push({
        code: `ZH2_${no}`,
        displayName: no,
      });
    }
  }
  return list;
}

function generateListForPl3SZZH(selectedTab) {
  let numbers = [];

  if (selectedTab === 'SZZH') {
    numbers = ['000', '059', '158', '279', '467', '001', '066', '159', '288', '468',
      '002', '067', '166', '289', '469', '003', '068', '167', '299', '477',
      '004', '069', '168', '333', '478', '005', '077', '169', '334', '479',
      '006', '078', '177', '335', '488', '007', '079', '178', '336', '489',
      '008', '088', '179', '337', '499', '009', '089', '188', '338', '555',
      '011', '099', '189', '339', '556', '012', '111', '199', '344', '557',
      '013', '112', '222', '345', '558', '014', '113', '223', '346', '559',
      '015', '114', '224', '347', '566', '016', '115', '225', '348', '567',
      '017', '116', '226', '349', '568', '018', '117', '227', '355', '569',
      '019', '118', '228', '356', '577', '022', '119', '229', '357', '578',
      '023', '122', '233', '358', '579', '024', '123', '234', '359', '588',
      '025', '124', '235', '366', '589', '026', '125', '236', '367', '599',
      '027', '126', '237', '368', '666', '028', '127', '238', '369', '667',
      '029', '128', '239', '377', '668', '033', '129', '244', '378', '669',
      '034', '133', '245', '379', '677', '035', '134', '246', '388', '678',
      '036', '135', '247', '389', '679', '037', '136', '248', '399', '688',
      '038', '137', '249', '444', '689', '039', '138', '255', '445', '699',
      '044', '139', '256', '446', '777', '045', '144', '257', '447', '778',
      '046', '145', '258', '448', '779', '047', '146', '259', '449', '788',
      '048', '147', '266', '455', '789', '049', '148', '267', '456', '799',
      '055', '149', '268', '457', '888', '056', '155', '269', '458', '889',
      '057', '156', '277', '459', '899', '058', '157', '278', '466', '999'
    ];
  } else if (selectedTab === 'ZHS') {
    numbers = ['001', '112', '225', '344', '558', '002', '113', '226', '355', '559',
      '003', '114', '227', '366', '566', '004', '115', '228', '377', '577',
      '005', '116', '229', '388', '588', '006', '117', '233', '399', '599',
      '007', '118', '244', '445', '667', '008', '119', '255', '446', '668',
      '009', '122', '266', '447', '669', '011', '133', '277', '448', '677',
      '022', '144', '288', '449', '688', '033', '155', '299', '455', '699',
      '044', '166', '334', '466', '778', '055', '177', '335', '477', '779',
      '066', '188', '336', '488', '788', '077', '199', '337', '499', '799',
      '088', '223', '338', '556', '889', '099', '224', '339', '557', '899'
    ];
  } else if (selectedTab === 'ZHL') {
    numbers = ['012', '048', '139', '247', '369', '013', '049', '145', '248', '378',
      '014', '056', '146', '249', '379', '015', '057', '147', '256', '389',
      '016', '058', '148', '257', '456', '017', '059', '149', '258', '457',
      '018', '067', '156', '259', '458', '019', '068', '157', '267', '459',
      '023', '069', '158', '268', '467', '024', '078', '159', '269', '468',
      '025', '079', '167', '278', '469', '026', '089', '168', '279', '478',
      '027', '123', '169', '289', '479', '028', '124', '178', '345', '489',
      '029', '125', '179', '346', '567', '034', '126', '189', '347', '568',
      '035', '127', '234', '348', '569', '036', '128', '235', '349', '578',
      '037', '129', '236', '356', '579', '038', '134', '237', '357', '589',
      '039', '135', '238', '358', '678', '045', '136', '239', '359', '679',
      '046', '137', '245', '367', '689', '047', '138', '246', '368', '789'
    ];
  }

  const list = numbers.map(number => ({
    code: `ZH3_${number}`,
    displayName: number
  }));

  return list;
}

function generateListForPL3EZDW(selectedTab) {
  const list = [];
  for (let i = 0; i < 100; i++) {
    const formattedNumber = i.toString().padStart(2, '0');
    const code = `${selectedTab}_${formattedNumber}`;
    const displayName = `${formattedNumber}`;
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForPL3SZDW() {
  const list = [];
  for (let i = 0; i < 1000; i++) {
    const formattedNumber = i.toString().padStart(3, '0');
    const code = `BSGDW_${formattedNumber}`;
    const displayName = `${formattedNumber}`;
    list.push({
      code, displayName
    });
  }
  return list;
}


function checkCriteria(digit, combinedCriteria, template) {
  const hasOdd = combinedCriteria.includes('单');
  const hasEven = combinedCriteria.includes('双');
  const hasLarge = combinedCriteria.includes('大');
  const hasSmall = combinedCriteria.includes('小');
  const hasPrimeNumber = combinedCriteria.includes('质');
  const hasCompositeNumber = combinedCriteria.includes('合');

  let oddEvenCheck = true;
  let largeSmallCheck = true;
  let primeNumberCheck = true;

  if (template === 'PL3') {
    if (hasOdd) {
      oddEvenCheck = digit % 2 !== 0;
    } else if (hasEven) {
      oddEvenCheck = digit % 2 === 0;
    }
    if (hasPrimeNumber) {
      primeNumberCheck = digit === 1 || digit === 2 || digit === 3 || digit === 5 || digit === 7;
    } else if (hasCompositeNumber) {
      primeNumberCheck = digit === 0 || digit === 4 || digit === 6 || digit === 8 || digit === 9;
    }
  }
  if (hasLarge && hasSmall) { /* empty */ } else if (hasLarge) {
    largeSmallCheck = digit >= 5;
  } else if (hasSmall) {
    largeSmallCheck = digit < 5;
  }

  return oddEvenCheck && largeSmallCheck && primeNumberCheck;
}

function generateListForPosition(position) {
  const list = [];
  for (let i = 0; i < 10; i++) {
    let code;
    if (position === 'hundredPosition') {
      code = `BDW_${i}`;
    } else if (position === 'tenPosition') {
      code = `SDW_${i}`;
    } else if (position === 'onePosition') {
      code = `GDW_${i}`;
    } else if (position === 'combination') {
      code = `A${i}`;
    }
    const displayName = `${i}`;
    list.push({
      code, displayName
    });
  }
  return list;
}

function generateListForPL3HH(_selectedItemArray, selectedTab) {
  const hundredPosition = _selectedItemArray.hundredPosition || [];
  const tenPosition = _selectedItemArray.tenPosition || [];
  const onePosition = _selectedItemArray.onePosition || [];
  const filteredResult = [];

  function filterNumber(number) {
    const arrNum = number.toString().split('').sort();

    if (selectedTab === 'ZLMS' && (arrNum[0] === arrNum[1] || arrNum[1] === arrNum[2])) {
      return;
    }

    if (selectedTab === 'ZSMS' && !((arrNum[0] === arrNum[1] && arrNum[1] !== arrNum[2]) || (arrNum[0] !== arrNum[1] && arrNum[1] === arrNum[2]))) {
      return;
    }

    return number;
  }

  if (hundredPosition.length && tenPosition.length && onePosition.length) {
    for (let i = 0; i < hundredPosition.length; i++) {
      const num1 = hundredPosition[i];
      for (let j = 0; j < tenPosition.length; j++) {
        const num2 = tenPosition[j];
        for (let k = 0; k < onePosition.length; k++) {
          const num3 = onePosition[k];
          const number = `${num1}${num2}${num3}`;

          const n = filterNumber(number);
          if (n) {
            filteredResult.push(n);
          }
        }
      }
    }
  }

  return filteredResult.map(number => ({
    code: `BSGDW_${number}`,
    displayName: number
  }));
}

function calculateAmounts(formValues, initialNumLuckyNumbers) {
  const multipliers = {
    2: [4, 1, 5, 27],
    3: [3, 1, 4, 23],
    4: [0, 1, 0, 20]
  };

  const updatedFormValues = {
    ...formValues
  };

  for (let i = 0; i < initialNumLuckyNumbers; i++) {
    const luckyNumber = formValues[`luckyNumber_${i}`];
    if (luckyNumber !== undefined) {
      const { length } = luckyNumber;

      if (multipliers[length]) {
        const [headMultiplier, tailMultiplier, headTailMultiplier, groupMultiplier] = multipliers[length];

        const head = parseInt(formValues[`head_${i}`], 10) || 0;
        const tail = parseInt(formValues[`tail_${i}`], 10) || 0;
        const headTail = parseInt(formValues[`headTail_${i}`], 10) || 0;
        const group = parseInt(formValues[`group_${i}`], 10) || 0;

        const headAmount = head * headMultiplier;
        const tailAmount = tail * tailMultiplier;
        const headTailAmount = headTail * headTailMultiplier;
        const groupAmount = group * groupMultiplier;
        const amount = headAmount + tailAmount + headTailAmount + groupAmount;

        updatedFormValues[`amount_${i}`] = amount;
        updatedFormValues[`headAmount_${i}`] = headAmount;
        updatedFormValues[`tailAmount_${i}`] = tailAmount;
        updatedFormValues[`headTailAmount_${i}`] = headTailAmount;
        updatedFormValues[`groupAmount_${i}`] = groupAmount;
      }
    }
  }

  return updatedFormValues;
}

function calculateTeShuAmounts(formValues, numRows) {
  const multipliers = {
    0: [200, 50, 250, 1350],
    1: [200, 50, 250, 1350],
    2: [200, 50, 250, 1350],
    3: [200, 50, 250, 1350],
    4: [100, 25, 125, 675],
    5: [100, 25, 125, 675],
    6: [100, 25, 125, 675],
    7: [100, 25, 125, 675],
    8: [100, 25, 125, 675],
    9: [100, 25, 125, 675],
    10: [100, 25, 125, 675],
    11: [100, 25, 125, 675],
    12: [200, 50, 250, 1350],
    13: [200, 50, 250, 1350],
  };

  const updatedFormValues = {
    ...formValues
  };

  for (let i = 0; i < numRows; i++) {
    if (formValues[`checkbox_${i}`]) {
      const multiplier = multipliers[i];
      const head = parseInt(formValues[`head_${i}`], 10) || 0;
      const tail = parseInt(formValues[`tail_${i}`], 10) || 0;
      const headTail = parseInt(formValues[`headTail_${i}`], 10) || 0;
      const group = parseInt(formValues[`group_${i}`], 10) || 0;

      const headAmount = head * multiplier[0];
      const tailAmount = tail * multiplier[1];
      const headTailAmount = headTail * multiplier[2];
      const groupAmount = group * multiplier[3];
      const amount = headAmount + tailAmount + headTailAmount + groupAmount;

      updatedFormValues[`amount_${i}`] = amount;
      updatedFormValues[`headAmount_${i}`] = headAmount;
      updatedFormValues[`tailAmount_${i}`] = tailAmount;
      updatedFormValues[`headTailAmount_${i}`] = headTailAmount;
      updatedFormValues[`groupAmount_${i}`] = groupAmount;
    }
  }

  return updatedFormValues;
}

const generateCodeBasedOnCheckbox = (checkboxValue, amountType) => {
  const prefix = 'TS';
  let postfix = '';

  switch (checkboxValue) {
    case '大':
      postfix = 'DA';
      break;
    case '小':
      postfix = 'X';
      break;
    case '单':
      postfix = 'D';
      break;
    case '双':
      postfix = 'S';
      break;
    case '大单':
      postfix = 'DAD';
      break;
    case '大双':
      postfix = 'DAS';
      break;
    case '小单':
      postfix = 'XD';
      break;
    case '小双':
      postfix = 'XS';
      break;
    case '单单':
      postfix = 'DD';
      break;
    case '双双':
      postfix = 'SS';
      break;
    case '单双':
      postfix = 'DS';
      break;
    case '双单':
      postfix = 'SD';
      break;
    case '内':
      postfix = 'N';
      break;
    case '外':
      postfix = 'W';
      break;
    default:
      return null;
  }

  switch (amountType) {
    case 'head':
      return `${prefix}_T_${postfix}`;
    case 'tail':
      return `${prefix}_W_${postfix}`;
    case 'headTail':
      return `${prefix}_TW_${postfix}`;
    case 'group':
      return `${prefix}_BZ_${postfix}`;
    default:
      return null;
  }
};

function generateCodeBasedOn2D3DCheckbox(checkboxValue, is3D) {
  let prefix = '2D';
  if (is3D) {
    prefix = '3D';
  } else {
    prefix = '2D';
  }
  switch (checkboxValue) {
    case '头':
      return `${prefix}_T`;
    case '尾':
      return `${prefix}_W`;
    case '头尾':
      return `${prefix}_TW`;
    case '包组':
      return `${prefix}_BZ`;
    default:
      return null;
  }
}

function generateCodeBasedSelectedTab(checkboxValue, selectedTab) {
  let prefix = '';
  if (selectedTab === '3D') {
    prefix = '3D';
  } else if (selectedTab === '2D') {
    prefix = '2D';
  } else if (selectedTab === '4D') {
    prefix = '4D';
  }
  switch (checkboxValue) {
    case '头':
      return `${prefix}_T`;
    case '尾':
      return `${prefix}_W`;
    case '头尾':
      return `${prefix}_TW`;
    case '包组':
      return `${prefix}_BZ`;
    default:
      return null;
  }
}

function generateUniqueCombinations(numbers, combinationLength = 2) {
  const combinations = [];
  const generateCombinations = (prefix, start) => {
    if (prefix.length === combinationLength) {
      combinations.push(prefix.join(','));
      return;
    }
    for (let i = start; i < numbers.length; i++) {
      generateCombinations([...prefix, numbers[i]], i + 1);
    }
  };
  generateCombinations([], 0);
  return combinations;
}

export {
  generateCodeBasedSelectedTab, generateUniqueCombinations, generateCodeBasedOn2D3DCheckbox, generateCodeBasedOnCheckbox, calculateTeShuAmounts, calculateAmounts, generateListForPL3HH, generateListForPL3SZDW, generateListForPL3EZDW, generateListForPosition, checkCriteria, generateListForPl3SZZH, generateListForPl3EZZH, generateListForPl3YZZH, getVisibilityClass, generateNumbersForZ2, generateNumbersForZ3, generateNumbersForZ4, generateCode, generateListForDW4, generateListForDW3, generateListForDW2, generateListForDW1, getNumByAnimal, validateNumber, validateQxcNumber, renderHeXiaoBalls, renderResultDateCell, renderSeBoBalls, renderWuXingBalls, renderWSBalls, inviteCodeValidation, quickBetDictionary
};