import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Web/Content/DataTable';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  balanceLogData: state.memberReducers.balanceLogData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function BalanceLogModal({ updateModalAlert, balanceLogData }) {
  const [_balanceLog, setBalanceLog] = useState();
  const [_balanceLogSum, setBalanceLogSum] = useState();
  const [_isQuerying, setIsQuerying] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(balanceLogData) !== JSON.stringify(_balanceLog)) {
      setBalanceLog(balanceLogData.list);
      setBalanceLogSum(balanceLogData.pageSum);
      setIsQuerying(false);
    }
  }, [balanceLogData]);

  return (
    <div className="balanceLogModalWrapper">
      <Row className="modalHeader w-100">
        <Col xs="4" />
        <Col
          className="d-flex justify-content-center align-items-center"
          xs="4"
        >
          {t('BET_INFO_MODAL_TITLE')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent p-3">
        <div className="p-0 item w-100">
          <DataTable
            isQuerying={_isQuerying}
            tableHeaderTitle={
              [{
                label: t('BET_INFO_HEADER_DATE'),
              }, {
                label: t('BET_INFO_HEADER_AMOUNT'),
              }, {
                label: t('BET_INFO_HEADER_WITHDRAW'),
              }, {
                label: t('BET_INFO_HEADER_DEPOSIT'),
              }, {
                label: t('BET_INFO_HEADER_TRANSFER'),
              }, {
                label: t('BET_INFO_HEADER_WIN_LOST'),
              }, {
                label: t('BET_INFO_HEADER_WITHDRAW_CHARGES'),
              }, {
                label: t('BET_INFO_HEADER_TOP_UP_CHARGES'),
              }]
            }
            tableData={_balanceLog}
            tableBodyRowProperty={
              ['widthDate',
                'balance',
                'amount200',
                'amount100',
                'amount899',
                'betWinlost',
                'fee200',
                'fee100',
              ]
            }
            tableFooter={[[{
              value: `${t('SUM')}:`,
              colspan: 2,
            }, {
              value: (_balanceLogSum && _balanceLogSum.amount200) || 0,
              colspan: 1,
            }, {
              value: (_balanceLogSum && _balanceLogSum.amount100) || 0,
              colspan: 1,
            }, {
              value: (_balanceLogSum && _balanceLogSum.amount899) || 0,
              colspan: 1,
            }, {
              value: (_balanceLogSum && _balanceLogSum.betWinlost) || 0,
              colspan: 1,
            }, {
              value: (_balanceLogSum && _balanceLogSum.fee200) || 0,
              colspan: 1,
            }, {
              value: (_balanceLogSum && _balanceLogSum.fee100) || 0,
              colspan: 1,
            }]]}
          />
        </div>
      </Row>
      <Row className="modalFooter p-2">
        <Col xs="12" className="justify-content-center d-flex">
          <Button
            type="button"
            className="confirmBtn"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CONFIRM')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(BalanceLogModal);