import { SET_API, SET_CURRENT_PANKOU, SET_MODAL_ALERT, SET_PLATFORM, SET_THEME_COLOR, SET_YI_LOU_COUNT, TOGGLE_GAME_BALANCE_PANEL, TOGGLE_GAME_MENU, TOGGLE_HORIZONTAL, TOGGLE_LENG_RE_BUTTON, TOGGLE_QUICK_BET_BUTTON, TOGGLE_RESULT, TOGGLE_YI_LOU_BUTTON } from '../../actionTypes';

const initialState = {
  data: undefined,

  platform: 'Mobile',
  isHorizontal: true,
  themeColor: undefined,
  showSubgameList: true,
  showLengRe: false,
  showYiLou: false,
  yiLouCount: 100,
  quickBetBtn: 'QUICKBET',
  modalAlert: {
    visible: false,
  },
  isGameMenuOpend: false,
  isGameBalancePanelOpend: false,
  oddsType: '',
  resultVisibility: false
};

const appReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PANKOU:
      return {
        ...state, oddsType: action.value
      };

    case TOGGLE_RESULT:
      return {
        ...state, resultVisibility: action.value
      };

    case SET_API:
      return {
        ...state, data: action.value
      };
    case TOGGLE_YI_LOU_BUTTON:
      return {
        ...state, showYiLou: !state.showYiLou
      };

    case TOGGLE_LENG_RE_BUTTON:
      return {
        ...state, showLengRe: !state.showLengRe
      };

    case SET_YI_LOU_COUNT:
      return {
        ...state, yiLouCount: action.value
      };

    case SET_PLATFORM:
      return {
        ...state, platform: action.value
      };

    case SET_MODAL_ALERT:
      return {
        ...state, modalAlert: action.value
      };

    case TOGGLE_HORIZONTAL:
      return {
        ...state, isHorizontal: !state.isHorizontal
      };

    case SET_THEME_COLOR:
      return {
        ...state, themeColor: action.value
      };
    case TOGGLE_QUICK_BET_BUTTON:
      return {
        ...state, quickBetBtn: action.value
      };
    case TOGGLE_GAME_MENU:
      return {
        ...state, isGameMenuOpend: action.value
      };
    case TOGGLE_GAME_BALANCE_PANEL:
      return {
        ...state, isGameBalancePanelOpend: action.value
      };
    default:
      return state;
  }
};

export default appReducers;