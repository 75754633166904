import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import zh from './zh';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      zh
    },
    lng: 'zh',
    fallbackLng: 'zh',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });