import { StatusCodes } from 'http-status-codes';

import { SET_COMPANYINFO } from '../../actionTypes';
import { apiService } from '../../../library';

const setCompanyInfo = companyInfo => ({
  type: SET_COMPANYINFO,
  value: companyInfo,
});

const getInfo = () => dispatch => {
  apiService.get('/company/getInfo').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setCompanyInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getInfo,
};