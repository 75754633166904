import { Suspense, lazy, useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { connect } from 'react-redux';

import zhCN from 'antd/es/locale/zh_CN';

import Login from './pages/Web/Login';

import { appActions } from './redux/actions';
import { store } from './redux';
import './App.scss';

const AuthLayout = lazy(() => import('./containers/Web/Auth/AuthLayout'));
const MobileAuthLayout = lazy(() => import('./containers/Mobile/Auth/AuthLayout'));
const Agreement = lazy(() => import('./pages/Web/Agreement'));
const MobilePublicLayout = lazy(() => import('./containers/Mobile/Public/PublicLayout'));
const NoMatch = lazy(() => import('./pages/Web/NoMatch'));

const mapStateToProps = state => ({
  userData: state.memberReducers.userData,
  themeColor: state.appReducers.themeColor,
  lotteryType: state.gameReducers.lotteryType,
  template: state.gameReducers.template,
  selectedSubType: state.gameReducers.selectedSubType,
  companyInfo: state.companyReducers.companyInfo,
});

const mapDispatchToProps = dispatch => ({
  updateThemeColor: data => dispatch(appActions.updateThemeColor(data)),
});

function App({ userData, updateThemeColor, themeColor, lotteryType, selectedSubType, template, companyInfo }) {
  useEffect(() => {
    const temp = localStorage.getItem('themeColor');
    if (!themeColor || !temp) {
      if (temp) {
        updateThemeColor(temp);
      } else {
        updateThemeColor('blue');
        localStorage.setItem('themeColor', 'blue');
      }
    }
    if (companyInfo && lotteryType) {
      const { company: { lotteryStyles } } = companyInfo || {};
      const { cssStyle } = lotteryStyles.find(x => x.lotteryType === lotteryType?.item) || {};
      if (cssStyle) {
        updateThemeColor(cssStyle);
      } else {
        updateThemeColor(temp || 'blue');
      }
    }
  }, [companyInfo, lotteryType]);

  const detectPlatform = () => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) !== null;
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
    const bIsMidp = sUserAgent.match(/midp/i) !== null;
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
    const bIsUc = sUserAgent.match(/ucweb/i) !== null;
    const bIsAndroid = sUserAgent.match(/android/i) !== null;
    const bIsCE = sUserAgent.match(/windows ce/i) !== null;
    const bIsWM = sUserAgent.match(/windows mobile/i) !== null;
    if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
      store.dispatch(appActions.updatePlatForm('Mobile'));
      return 'Mobile';
    }
    store.dispatch(appActions.updatePlatForm('Web'));
    return 'Web';
  };
  return (
    <div className={`theme-${themeColor || 'blue'} App`}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <Suspense>
            <Switch>
              {
              userData && !userData?.checkPassword && (
                <Route
                  path="/user"
                  name="User"
                  render={props => (
                    (detectPlatform() === 'Web'
                      ? <AuthLayout {...props} />
                      : <MobileAuthLayout {...props} />)
                  )}
                />
              )
            }
              {
              userData && userData?.checkPassword === true && detectPlatform() === 'Mobile' && (
                <Route
                  exact
                  path="/change-password"
                  name="Change Password"
                  render={props => <MobilePublicLayout {...props} />}
                />
              )
            }
              {userData && !userData?.checkPassword
            && <Route path="/agreement" name="Agreement" render={props => <Agreement {...props} />} />}
              <Route
                path="/"
                exact
                name="Home"
                render={props => (detectPlatform() === 'Web' ? <Login {...props} /> : (userData && lotteryType && template && selectedSubType) ? (
                  <Redirect to={{
                    pathname: '/user/games',
                  }}
                  />
                ) : <MobilePublicLayout {...props} />)}
              />
              <Route
                path="*"
                name="Not Found"
                render={props => (!userData ? (
                  <Redirect to={{
                    pathname: '/',
                  }}
                  />
                ) : <NoMatch {...props} />)}
              />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);