import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

const mobileBankCardDetailModal = ({ updateModalAlert, data }) => {
  const { t } = useTranslation();
  return (
    <div className="mobileBankCardDetailModal">
      <Row className="modalHeader m-0">
        <Col className="text-center">
          {t('BANK_CARD_DETAIL')}
        </Col>
      </Row>
      <Row className="modalContent d-flex justify-content-center mx-0">
        <Row className="mx-0 px-3 py-2 d-flex justify-content-center align-items-center">
          <span className="text-center w-25">{t('BANKCARD_ADD_CARD_CARD_NAME')}</span>
          <div className="border w-75 p-2">{data.bankUNameCut}</div>
        </Row>
        <Row className="mx-0 px-3 py-2 d-flex justify-content-center align-items-center">
          <span className="text-center w-25">{t('BANKCARD_CARD_LIST_HEADER')}</span>
          <div className="border w-75 p-2">{data.bankNumber}</div>
        </Row>
        <Row className="mx-0 px-3 py-2 d-flex justify-content-center align-items-center">
          <span className="text-center w-25">{t('BANK_CARD_BANK')}</span>
          <div className="border w-75 p-2">{data.bankName}</div>
        </Row>
        <Row className="mx-0 px-3 py-2 d-flex justify-content-center align-items-center">
          <span className="text-center w-25">{t('BANKCARD_ADD_CARD_CARD_BANK_ADDRESS')}</span>
          <div className="border w-75 p-2">{data.bankAddress}</div>
        </Row>
      </Row>
      <Row className="modalFooter d-flex align-items-center justify-content-center">
        <Button
          className="confirmButton my-2"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(mobileBankCardDetailModal);