import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

import GuaMa from '../../../constants/GuaMa/index';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function GuaMaModal({ platform, updateModalAlert, data }) {
  const { t } = useTranslation();
  return (
    <div className={`GuaMaModalWrapper${platform}`}>
      <div className="modalHeader d-flex">
        <div
          className={`${'pl-3 d-flex align-items-center w-50'}`}
        >
          挂码说明
        </div>
        <div className="d-flex justify-content-end align-items-center w-50 pr-3">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.overBet && data.onConfirmationClick) data.onConfirmationClick();
            }}
          />
        </div>
      </div>
      <div className="modalContent p-3">
        {
          GuaMa[`${data.templateCode}`]
            ? GuaMa[`${data.templateCode}`].map((desc, index) => (
              <div className="confirmText mb-2" key={`desc_${index.toString()}`}>
                {`${
                  index === 1 ? `取168kai.com ${data.lotteryName.indexOf('186') === 0 ? `${data.lotteryName.slice(3)}` : data.lotteryName}` : index === 2 ? `如:168kai.com ${data.lotteryName.indexOf('186') === 0 ? `${data.lotteryName.slice(3)}` : data.lotteryName}` : ''
                }${desc}`}
              </div>
            ))
            : <div>暂时没有挂码说明</div>
        }
      </div>
      <div className="modalFooter d-flex align-items-center">
        <Button
          className={`confirmButton my-3 ${platform === 'Web' ? 'mr-3' : 'ml-2 mr-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GuaMaModal);