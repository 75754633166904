import { SET_COMPANYINFO } from '../../actionTypes';

const initialState = {
  companyInfo: undefined,
};

const companyReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANYINFO:
      return {
        ...state, companyInfo: action.value
      };

    default:
      return state;
  }
};

export default companyReducers;