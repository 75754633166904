import { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { BsFillCircleFill } from 'react-icons/bs';
import { Slider } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, trendActions } from '../../../redux/actions';
import Label from '../../Label';

import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  saveTrendPreference: (lotteryType, min, isDelete, max) => dispatch(trendActions.saveTrendPreference(lotteryType, min, isDelete, max)),
});

const mapStateToProps = state => ({
  userGames: state.homeReducers.userGames,
  customChanglongData: state.trendReducers.customChanglongData,
  template: state.gameReducers.template,
  trendPreference: state.trendReducers.trendPreference,
});

function CustomiseChanglongModal({ trendPreference, userGames, updateModalAlert, saveTrendPreference, template }) {
  const [_lotteryList, setLotteryList] = useState([]);
  const [_templateCodeList] = useState([]);
  const [_selectedLotteryList, setSelectedLotteryList] = useState([]);
  const [_showSaveHint, setShowSaveHint] = useState(false);
  const { t } = useTranslation();
  const [_min, setMin] = useState(2);
  const [_max, setMax] = useState(10);

  useEffect(() => {
    if (trendPreference) {
      setMin(trendPreference.minCount);
      setMax(trendPreference.maxCount);
    }
    const list = userGames.lotteryList.filter(item => item.templateCode !== 'K3' && item.templateCode !== 'FANT');
    setLotteryList(list);
    list.forEach(lottery => _templateCodeList && _templateCodeList.filter(template => template === lottery.templateCode).length === 0 && _templateCodeList.push(lottery.templateCode));
    const tempList = trendPreference?.list ? trendPreference.list : list.filter(lottery => lottery.templateCode === template).slice(0, 4).map(item => item.code);
    setSelectedLotteryList(tempList);
  }, [trendPreference]);

  const selectAllLotteries = () => {
    setShowSaveHint(false);
    const selected = JSON.parse(JSON.stringify(_selectedLotteryList));
    _lotteryList.forEach(item => !selected.includes(item.code) && selected.push(item.code));
    setSelectedLotteryList(selected);
  };

  const unSelectAllLotteries = () => {
    setSelectedLotteryList([]);
  };

  const selectAllTemplate = templateCode => {
    setShowSaveHint(false);
    const selectedLottery = JSON.parse(JSON.stringify(_selectedLotteryList));
    const templateCodeLotteries = _lotteryList.filter(item => item.templateCode === templateCode);
    const possibleLotteries = _lotteryList.filter(item => item.templateCode === templateCode && !selectedLottery.includes(item.code));
    if (possibleLotteries.length > 0) {
      possibleLotteries.forEach(item => !selectedLottery.includes(item.code) && selectedLottery.push(item.code));
    } else {
      templateCodeLotteries.forEach(lottery => selectedLottery.splice(selectedLottery.indexOf(lottery.code), 1));
    }
    setSelectedLotteryList(selectedLottery);
  };

  const clickLottery = lottery => {
    setShowSaveHint(false);
    const beforeChanges = JSON.parse(JSON.stringify(_selectedLotteryList));
    if (_selectedLotteryList.includes(lottery.code)) {
      beforeChanges.splice(_selectedLotteryList.indexOf(lottery.code), 1);
      setSelectedLotteryList(beforeChanges);
    } else {
      beforeChanges.push(lottery.code);
      setSelectedLotteryList(beforeChanges);
    }
  };

  const onSubmit = () => {
    if (_selectedLotteryList.length === 0) {
      setShowSaveHint(true);
      return;
    }
    saveTrendPreference(_selectedLotteryList && _selectedLotteryList.toString(), _min, _selectedLotteryList.length < 1, _max);
    updateModalAlert({
      visible: false
    });
  };

  return (
    <div className="customiseChanglongModalWrapperWeb">
      <Row className="modalHeader">
        {t('CUSTOMIZE_CAIZHONG')}
      </Row>
      <Row>
        <Col className="px-0 modalContent">
          <Row className="py-3 bottomLine">
            <Col className="col-6">
              <Row className="orangeOutlined">
                <Col className="col-3 orangeBgwhiteText">
                  {t('CHANGLONG_FREQUENCY_SELECT')}
                </Col>
                <Col className="py-1">
                  <Row className="align-items-center">
                    <Col className="px-0 orangeText">
                      2
                    </Col>
                    <Col className="col-10">
                      <Slider
                        key={`slider-${[_min, _max].toString()}`}
                        value={[_min, _max]}
                        max={10}
                        min={2}
                        range
                        onChange={value => {
                          setMin(value[0]);
                          setMax(value[1]);
                        }}
                      />
                    </Col>
                    <Col className="px-0 orangeText">
                      10
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col />
            <Col className="col-4">
              <Row className="topButtonRow">
                <div className={`button ${_selectedLotteryList.length === _lotteryList.length && 'selected'}`} onClick={() => selectAllLotteries()}>
                  {t('CHANGLONG_SELECT_ALL')}
                </div>
                <div className="button" onClick={() => unSelectAllLotteries()}>
                  {t('CHANGLONG_CLEAR')}
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="scrollableArea bottomLine">
            {_templateCodeList.map(templateCode => (
              <Row className="py-2 templateRow top">
                <Col className="col-2 d-flex justify-content-center align-items-center px-0">
                  <div
                    className="optionItemWrapper w-100"
                    onClick={() => selectAllTemplate(templateCode)}
                  >
                    <span className={`optionWrapper ${_lotteryList.filter(lottery => lottery.templateCode === templateCode).filter(lottery => _selectedLotteryList && _selectedLotteryList.includes(lottery.code)).length === _lotteryList.filter(lottery => lottery.templateCode === templateCode).length ? 'active' : 'inactive'}`}>
                      <BsFillCircleFill
                        className={`${_lotteryList.filter(lottery => lottery.templateCode === templateCode).filter(lottery => _selectedLotteryList && _selectedLotteryList.includes(lottery.code)).length === _lotteryList.filter(lottery => lottery.templateCode === templateCode).length ? 'active' : 'inactive'}`}
                      />
                    </span>
                    <Label className="templateCode active" value={t(`_${templateCode}_SERIES`)} />
                  </div>
                </Col>
                <Col>
                  <Row>
                    {_lotteryList.filter(lottery => lottery.templateCode === templateCode).map(lottery => (
                      <div className={`mr-3 my-1 button ${_selectedLotteryList && _selectedLotteryList.includes(lottery.code) && 'selected'}`} onClick={() => clickLottery(lottery)}>
                        {lottery.lotteryName}
                      </div>
                    ))}
                  </Row>
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="modalFooter py-3">
        <Col className="col-4 d-flex align-items-center justify-content-end text-danger">{_showSaveHint && '*至少选择一个彩种'}</Col>
        <Col className="col-2">
          <div className="button selected" onClick={() => onSubmit()}>
            {t('SAVE')}
          </div>
        </Col>
        <Col className="col-2">
          <div
            className="button"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CANCEL')}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomiseChanglongModal);