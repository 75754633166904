import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Label from '../../Label';
import { ReactComponent as Tick } from '../../../assets/icon/TopUpIcons/icon_tick.svg';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function SuccessModal({ platform, updateModalAlert, data }) {
  const { t } = useTranslation();
  return (
    <div className={`successModalWrapper${platform}`}>
      <Row className="modalHeader mx-0 ">
        <Col
          className="d-flex  align-items-center"
        >
          {t('REMINDER')}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className={`modalContent ${platform === 'Mobile' && 'd-flex justify-content-center'}`}>
        <Row className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4 mt-5' : 'my-2'}`}>
          <Tick className="successTick" />
        </Row>
        <Row className={`d-flex justify-content-center ${platform === 'Web' && 'mb-4'}`}>
          <Label className="successText" value={data && data.message} />
        </Row>
      </Row>
      <Row className={`modalFooter d-flex align-items-center ${platform === 'Mobile' && 'justify-content-center'}`}>
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CONFIRM')}
        </Button>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);