import { combineReducers } from 'redux';

import appReducers from './appReducers';
import bettingListReducers from './bettingListReducers';
import cartReducers from './cartReducers';
import cashReducers from './cashReducers';
import companyReducers from './companyReducers';
import gameReducers from './gameReducers';
import gameRulesReducers from './gameRulesReducers';
import homeReducers from './homeReducers';
import memberReducers from './memberReducers';
import noticeReducers from './noticeReducers';
import pingReducers from './pingReducers';
import placeBetReducers from './placeBetReducers';
import resultHistoryReducers from './resultHistoryReducers';
import thirdpartygamesReducers from './thirdpartygamesReducers';
import topupReducers from './topupReducers';
import trendReducers from './trendReducers';
import withdrawalReducers from './withdrawReducers';

const appReducer = combineReducers({
  appReducers,
  bettingListReducers,
  cartReducers,
  cashReducers,
  companyReducers,
  gameReducers,
  gameRulesReducers,
  homeReducers,
  memberReducers,
  noticeReducers,
  pingReducers,
  placeBetReducers,
  resultHistoryReducers,
  thirdpartygamesReducers,
  topupReducers,
  withdrawalReducers,
  trendReducers,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;