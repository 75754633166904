import { LATEST_RESULT, SET_CHANGLONG_DATA, SET_CUSTOM_CHANGLONG, SET_TREND_PREFERENCE } from '../../actionTypes';

const initialState = {
  changlongData: undefined,
  customChanglongData: undefined,
  trendPreference: undefined,
  latestResult: [],
};

const trendReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANGLONG_DATA:
      return {
        ...state,
        changlongData: action.value
      };
    case SET_TREND_PREFERENCE:
      return {
        ...state, trendPreference: action.value
      };
    case SET_CUSTOM_CHANGLONG:
      return {
        ...state,
        customChanglongData: action.value
      };
    case LATEST_RESULT:
      return {
        ...state,
        latestResult: action.value
      };

    default:
      return state;
  }
};

export default trendReducers;