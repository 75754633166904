import { APPEND_CURRENT_BETS, RESET_CURRENT_BETS, SET_COUNT_DOWN_TIMER, SET_CURRENT_BETS, SET_GAME_INFO, SET_GAME_ODDS } from '../../actionTypes';

const initialState = {
  gameInfo: undefined,
  countDownTimer: 10,
  currentBets: [],
};

const placeBetReducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CURRENT_BETS:
      return {
        ...state, currentBets: []
      };

    case SET_CURRENT_BETS:
      return {
        ...state, currentBets: [...action.value]
      };

    case APPEND_CURRENT_BETS:
      return {
        ...state, currentBets: [...state.currentBets, ...action.value]
      };

    case SET_GAME_INFO: {
      const { isRefresh, oddsList: newOddsList } = action.value || {};
      if (isRefresh) {
        let tempOddsList;
        if (newOddsList) {
          const { oddsList } = state.gameInfo || {};
          tempOddsList = {
            ...oddsList,
            ...newOddsList
          };
        }
        const tempGameInfo = {
          ...action.value,
          oddsList: tempOddsList
        };
        return {
          ...state, gameInfo: tempGameInfo
        };
      }
      return {
        ...state, gameInfo: action.value
      };
    }

    case SET_GAME_ODDS: {
      const temp = {
        ...state.gameInfo
      };
      delete temp.oddsList;
      return {
        ...state,
        gameInfo: {
          ...temp,
        }
      };
    }

    case SET_COUNT_DOWN_TIMER:
      return {
        ...state, countDownTimer: action.value
      };

    default:
      return state;
  }
};

export default placeBetReducers;