import { useEffect, useState } from 'react';

import { ReactComponent as EmptyIcon } from '../../../../assets/image/order_empty.svg';
import Skeleton from '../../../Skeleton';

import './styles.scss';

function DataTable({ tableHeaderTitle, tableHeaderStyle, tableData, tableBodyRowProperty, tableBodyRowStyle, tableFooter, onRowClick, isQuerying }) {
  const [_data, setData] = useState();
  useEffect(() => {
    if (JSON.stringify(_data) !== JSON.stringify(tableData)) {
      setData(tableData);
    }
  }, [tableData, isQuerying]);

  useEffect(() => {
    if (isQuerying) {
      setData(new Array(10).fill(''));
    }
  }, [isQuerying]);

  return (
    <div className="dataTableWrapper">
      <table className="dataTable" cellSpacing="0" cellPadding="0" border="0">
        <thead>
          <tr className="tableHeader">
            {tableHeaderTitle.map((ele, index) => (
              Array.isArray(ele)
                ? (
                  <th key={`header_title_${index.toString()}`}>
                    {ele.map((subEle, subIndex) => (
                      <span key={`sub_header_title_${subIndex.toString()}`} className={tableHeaderStyle && tableHeaderStyle[index] && tableHeaderStyle[index][subIndex]}>
                        {subEle.label}
                      </span>
                    ))}
                  </th>
                )
                : (
                  <th key={`header_title_${index.toString()}`} className={ele.className || ''}>
                    <span className={tableHeaderStyle && tableHeaderStyle[index]}>{ele.label}</span>
                  </th>
                )))}
          </tr>
        </thead>
        <tbody>
          {
          _data && _data.length > 0 ? _data.map((item, index) => (
            <tr className="tableBodyRow" key={`table_row_${index.toString()}`} onClick={() => onRowClick && onRowClick(item)}>
              {tableBodyRowProperty.map((property, propertyIndex) => (Array.isArray(property)
                ? (
                  <td key={`td_sub_item_${propertyIndex.toString()}`}>
                    {
                      isQuerying ? (
                        <Skeleton isAnimated />
                      ) : (
                        property.map((subProperty, subIndex) => (
                          <span className={tableBodyRowStyle && tableBodyRowStyle[propertyIndex] && tableBodyRowStyle[propertyIndex][subIndex]}>
                            {typeof subProperty === 'function' ? property(item, index) : item[subProperty] || subProperty}
                          </span>
                        ))
                      )
                    }
                  </td>
                )
                : (
                  <td className={tableBodyRowStyle && tableBodyRowStyle[propertyIndex]}>
                    {
                      isQuerying ? (
                        <Skeleton isAnimated />
                      ) : typeof property === 'function' ? property(item, index) : (<span>{item[property]}</span>)
                    }
                  </td>
                )))}
            </tr>
          )) : (
            <tr>
              <td colSpan={tableHeaderTitle.length}>
                <EmptyIcon className="emptyIcon" />
                <div className="emptyTextWrapper">
                  <span>暂无记录</span>
                </div>
              </td>
            </tr>
          )
        }
          {
          tableFooter && tableFooter.map(footerRow => (
            <tr>
              {
                footerRow.map((item, index) => (
                  <td key={`table_footer_${index.toString()}`} colSpan={item.colspan} className={item.className}>
                    {
                      item.render ? item.render() : <span>{item.value}</span>
                    }
                  </td>
                ))
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;