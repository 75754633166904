import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Exclamation } from '../../../assets/icon/TopUpIcons/icon_exclamation.svg';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function ConfirmationModal({ platform, updateModalAlert, data }) {
  const { t } = useTranslation();
  return (
    <div className={`confirmModalWrapper${platform}`}>
      <div className="modalHeader d-flex mx-0">
        <div className=" d-flex  align-items-center col-6">
          {t('REMINDER')}
        </div>
        <div className="d-flex justify-content-end align-items-center col-6">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.onConfirmationClick) data.onConfirmationClick();
            }}
          />
        </div>
      </div>
      <div className="modalContent mx-0 d-flex">
        <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4 mt-5' : 'mt-2 mb-1'}`}>
          <Exclamation className="exclamationIcon" />
        </div>
        <div className={`d-flex justify-content-center ${platform === 'Web' ? 'mb-4' : 'mb-1'}`}>
          <Label className="confirmText" value={data && data.message} />
        </div>
      </div>
      <div className="modalFooter d-flex align-items-center mx-0">
        <Button
          className={`confirmButton my-3 ${platform === 'Web' ? 'mr-3' : 'ml-2 mr-3'}`}
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
        {
          data && data.showCancelButton !== false && (
            <Button
              className={`cancelButton my-3 ${platform === 'Web' ? 'mr-3' : 'mr-2'}`}
              onClick={() => {
                updateModalAlert({
                  visible: false
                });
                if (data && data.onCancelClick) data.onCancelClick();
              }}
            >
              {(data && data.cancelText) || t('CANCEL')}
            </Button>
          )
        }
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);