export default {
  translation: {
    COMPANY_NAME: 'CJCSJ',
    USER_ACCOUNT: 'User Account',
    BET_TYPE: 'Bet Type',
    CREDIT_AMOUNT: 'Credit Amount',
    UNSETTLED_BALANCE: 'Unsettled Balance',
    TM: 'TM',
    TODAY_WINNING: 'Today Winning',
    TIME_TO_OPEN: 'Time to open',
    TIME_TO_CLOSE: 'Time to close',
    SECONDS: 'seconds',
    INSTALLMENTS: 'Installments',
    RECENT_DRAWS: 'Recent Draw',
    CUSTOMIZE_CAIZHONG: 'Customize Caizhong',
    TMA: 'TMA',
    TMB: 'TMB',
    QUICKBET: 'QuickBet',
    LIANG_MIAN_CHANG_LONG: 'LiangMian ChangLong',
    CURRENTLY_MISSING: 'Currently Missing',
    HOT_COLD_MISSING: 'Hot Cold Missing',
    CUSTOMER_SERVICE: 'Customer Service',
    HOME: 'Home',
    DEPOSIT: 'Desposit',
    TOP_UP: 'Top Up',
    RESULT: 'Result',
    MY: 'Profile',
    FORGET_PASSWORD: 'Forget Password',
    FORGET_PASSWORD_HINT: 'Forget Password Hint',
    ACCOUNT: 'Account',
    PHONE: 'Phone',
    VERIFY_CODE: 'Verify Code',
    GET_VERIFY_CODE: 'Get Verify Code',
    NEXT: 'Next',
    CHANGE_PASSWORD: 'Change Password',
    CHANGE_PASSWORD_HINT: 'Change Password Hint',
    SET_NEW_PASSWORD: 'Set New Password',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    CONFIRM: 'Confirm',
    PASSWORD_RULE: 'Password Rule',
    REGISTER: 'Register',
    AFFILIATE_CODE: 'Affiliate Code',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    SMS_VERIFY_CODE: 'Sms Verify Code',
    AFFILIATE_REQUIRED_HINT: 'Affiliate Required Hint',
    AFFILIATE_NOT_REQUIRED_HINT: 'Affiliate Not Required Hint',
    LOGIN: 'Login',
    REMEMBER_PASSWORD: 'Remember Password',
    FREE_TRIAL: 'Free Trial',
    SETTLED: 'Setteld',
    UNSETTLED: 'Unsettled',
    INFO: 'Info',
    RULES: 'Rules',
    LOGOUT: 'Logout',
    THEME: 'Theme',
    MCHK6: 'MCHK6',
    HK6: 'HK6',
    JSSCPK10: 'PK10',
    JSHK6: 'JSHK6',
    JSSSC: 'JSSSC',
    GXK10: 'GXK10',
    JSK3: 'JSK3',
    DXTB: 'DXTB',
    YXXTB: 'YXXTB',
    LX: 'Lx',
    LW: 'Lw',
    LM: 'Lm',
    ORDER_NUMBER: 'Order Number',
    BET_AMOUNT: 'Bet Amount',
    WIN_AMOUNT: 'Win Amount',
    LIANGMIAN: 'Liang Mian',
    MORE: 'More',
    CURRENT_SELECTED: 'Current Selected',
    LATEST_BET: 'Lastest Bet',
    COUNTRY_DRAWING_NET: 'Country Drawing Net',
    DRAW: 'Draw',
    MODAL_INFO: 'Hint',
    MODAL_ERROR: 'Error',
    MODAL_ACKNOWLEDGE: 'OK',
    LOGIN_ERROR_EMPTY_PASSWORD: 'Password cannot be empty',
    LOGIN_ERROR_USERNAME_LENGTH: 'Username error! Please enter 4 - 12 alpha-decimals',
    LOGIN_CHANGE_PASSWORD_REQUIRED_HEADER: 'Reset Password',
    LOGIN_GO_TO_CHANGE_PASSWORD_REQUIRED: 'Reset Password',
    LOADING: 'Loading',
    LOGIN_ERROR_EMPTY_AFFCODE: 'Affiliate Code cannot be empty',
    REGISTER_ERROR_REQUIRED_USERNAME: 'username cannot be empty',
    REGISTER_ERROR_CONFIRM_PASSWORD: 'Confirm Password cannot be empty',
    REGISTER_ERROR_PASSWORD_MISMATCHED: 'Password mismatched',
    REGISTER_ERROR_PHONE_INVALID: 'Invalid Phone number',
    REGISTER_ERROR_EMPTY_SMS: 'Sms code cannot be empty',
    REGISTER_ERROR_EMPTY_CAPTCHA: 'Captcha code cannot be empty',
    QUICK_SELECTION_AMOUNT: 'Quick Selection Amount',
    RESET: 'Reset',
    PRESET: 'Preset',
    AMOUNT: 'Amount',
    GYJZH: 'Gyjzh',
    DH: '1~10',
    DH15: '1~5',
    D1Q: '1st Ball',
    D2Q: '2nd Ball',
    D3Q: '2rd ball',
    D4Q: '4th ball',
    D5Q: '5th ball',
    TOP_UP_AMOUNT: 'Top Up Amount',
    TOP_UP_INTEREST_WARN_FRONT: 'This transaction shall deduct',
    TOP_UP_INTEREST_WARN_BACK: 'handling fee',
    TOP_UP_CUSTOMER_HELP_FRONT: 'Having trouble? Contact',
    IN_PERSON_SERVICE: 'In-Person service',
    TOP_UP_CUSTOMER_HELP_BACK: 'resolution',
    WITHDRAWAL: 'Withdrawal',
    WITHDRAWAL_EXPLAINATION: 'Withdrawal Explaination',
    WITHDRAWAL_PENDING_AMOUNT: 'Withdrawal Pending Amount',
    WITHDRAWAL_SAFE_PASSWORD: 'Withdrawal Safe Password',
    WALLET_BALANCE: 'Wallet Balance',
    BANK_WITHDRAWAL_AMOUNT: 'Withdrawal Amount',
    BANK_WITHDRAWAL_CARD: 'Withdrawal Bank Card',
    BALANCE_MANAGEMENT: 'Balance Management',
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    WITHIN7DAYS: 'Within 7 Days',
    WITHIN30DAYS: 'Within 30 Days',
    TRANSACTION_DETAILS: 'Transaction Details',
    TRANSACTION_ID: 'Transaction Id',
    USERNAME: 'Username',
    TRANSACTION_TYPE: 'Transaction Type',
    TRANSACTION_TIME: 'Transaction Time',
    TRANSACTION_AMOUNT: 'Transaction Amount',
    TRANSACTION_PROCESSING_FEE: 'Processing Fees',
    TRANSACTION_STATUS: 'Transaction Status',
    TRANSACTION_DETAIL: 'Transaction Detail',
    TRANSACTION_REMARK: 'Transaction Remark',
    TRANSACTION_APPROVAL_CODE: 'Transaction Approval Code',
    ZH: 'Zheng He',
    HC15: '1-5ball',
    YZZH: 'YZZH',
    EZZH: 'EZZH',
    SZZH: 'SZZH',
    EZDW: 'EZDW',
    SZDW: 'SZDW',
    EZHS: 'EZHS',
    SZHS: 'SZHS',
    ZX3: 'ZX3',
    ZX6: 'ZX6',
    FSZH: 'FSZH',
    KD: 'KD',
    ZHLH: 'ZHLH',
    NNSH: 'NNSH',
    UNSETTLED_BET: 'Unsettled',
    BET_ID: 'Bet Id',
    PLAYSTYLE: 'Type',
    BET: 'Bet',
    POTENTIAL_WINNING: 'Potential Winning',
    TOTAL_BET_COUNT: 'Total Bet Count',
    SETTLED_BET: 'Settled',
    WINNING_RESULT: 'Winning Result',
    WIN_LOSE: 'Win/Lose',
    BET_REPORT: 'Bet Report',
    UNTIL: '-',
    CHECK: 'Check',
    DATE: 'Date',
    REPORT_BET_AMOUNT: 'Bet Amount',
    REPORT_REBATE_AMOUNT: 'Rebate Amount',
    PROFIT_LOSS: 'P/L',
    PLEASE_SELECT_DATE: 'Please select date',
    CANCEL: 'Cancel',
    REDEEM_CODE: 'Redeem Code',
    CONFIRM_REDEEM: 'Confirm Redeem',
    PLEASE_ENTER_REDEEM_CODE: 'Please enter redeem code',
    CODE_REDEMPTION: 'Code Redemption',
    VERSION: 'Version',
    ALL_GAMES: 'All Games',
    ALL_RECORDS: 'All Records',
    THIRD_PARTY_RECORDS: 'Third Party Records',
    PLEASE_SELECT: 'Please select',
    SAFETY_CENTER: 'Safety Center',
    SAFETY_CENTER_HEADER_TEXT: 'Play with safety protection',
    SAFETY_CENTER_USERINFO: 'Personal Info',
    SAFETY_CENTER_USERINFO_SUBHEADER: 'Change Personal Info',
    SAFETY_CENTER_BINDPHONE: 'Bind Phone',
    SAFETY_CENTER_BINDPHONE_SUBHEADER: 'Bind Phone, easier and faster verification',
    SAFETY_CENTER_BINDCARD: 'Bind Bank Card',
    SAFETY_CENTER_CARD_LIST: 'Show Card List',
    SAFETY_CENTER_CARD_LIST_SUBHEADER: 'View my binded card',
    SAFETY_CENTER_BINDCARD_SUBHEADER_FRONT: 'Maximum number of binded cards: ',
    SAFETY_CENTER_BINDCARD_SUBHEADER_MIDDLE: '. Current number of  binded cards: ',
    SAFETY_CENTER_BINDCARD_SUBHEADER_BACK: 'piece',
    SAFETY_CENTER_BINDADDRESS: 'Receiving Address',
    SAFETY_CENTER_BINDADDRESS_SUBHEADER: 'Rewards receiving address',
    SAFETY_CENTER_LOGIN_PASSWORD: 'Change Login Password',
    SAFETY_CENTER_LOGIN_PASSWORD_SUBHEADER: 'Peroidically change for account safety',
    SAFETY_CENTER_WITHDRAW_PASSWORD: 'Withdraw Password',
    SAFETY_CENTER_WITHDRAW_PASSWORD_SUBHEADER: 'Change Withdraw Password',
    SAFETY_CENTER_UNBINDED: 'Unbinded',
    PERSONAL_INFORMATION: 'Personal Information',
    NAME: 'Name',
    DATEOFBIRTH: 'Date Of Birth',
    GENDER: 'Gender',
    LOTTERY_RULES_IMPORTANT_NOTICE: 'Important Notice',
    LOTTERY_RULES_RULES_EXPLANATION: 'Rules explain',
    LOTTERY_RULES_ALL_BETS_INCLUDE_PRINCIPLE: 'All Bets include Principal',
    LOTTERY_RULES_ALL_DETAILED_RULES: 'Detailed Rules',
    BET_TIME: 'Bet Time',
    INSTALLMENTS_NUM: 'Round',
    TYPE: 'type',
    BET_CONTENT: 'Bet Content',
    PAN_KOU: 'Pan Kou',
    REBATE: 'Return',
    ODDS: 'odds',
    TOTAL: 'tatal',
    TIMES: 'times',
    DI: 'di',
    HOME_PAGE: 'homepage',
    PREVIOUS_PAGE: 'previous page',
    NEXT_PAGE: 'next page',
    LAST_PAGE: 'last page',
    BET_LIMIT: 'Individual Information',
    BET_LIMIT_MIN: 'Bet Max',
    BET_LIMIT_MAX: 'Bet Min',
    BET_LIMIT_WEEKLYMAX: 'Installemt Max',
    PROMOTION: 'Promotions',
    PROMOTION_ALL: 'All',
    PROMOTION_DEPOSIT_WITHDRAWAL: 'Deposit/Withdraw Freebies',
    PROMOTION_LOTTERY_VOUCHER: 'Lottery Voucher',
    PROMOTION_OTHERS: 'Others',
    BET_NUMBER: 'Bet Number',
    DATE_RANGE: 'Date Range',
    START_END_DATE: 'Start Date',
    THIS_WEEK: 'This Week',
    PREVIOUS_WEEK: 'Previous Week',
    PREVIOUS_2_WEEK: 'Previous 2 Weeks',
    THIS_MONTH: 'This Month',
    PREVIOUS_MONTH: 'Previous Month',
    SAVE: 'Save',
    RESET_DEFAULT: 'Reset Default',
    USER_CENTER: 'User center',
    PLEASE_CONFIRM_BET: 'Please confirm bet',
    NUMBER: 'Number',
    CONFIRM_BETS: 'Confirm',
    CONFIRM_BET_TOTAL: 'Total',
    CONFIRM_BET_ITEM: 'item',
    YUAN: '¥',
    CONFIRM_BET_ACCOUNT_BALANCE: 'Balance',
    AGREE: 'Agree',
    DO_NOT_AGREE: 'Do Not Agree',
    DELETE: 'Delete',
    RIGHT_NOW: 'Right now',
    RECENT_1_WEEK: 'Recent 1 week',
    CURRENT_WEEK: 'Current week',
    RECENT_2_WEEK: 'Recent 2 weeks',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    MAX_DATE: 'Max Date',
    APPLY: 'Apply',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    BET_INFO_BASIC_INFO: 'Basic Info',
    BET_INFO_ACCOUNT_BALANCE: 'Account Balance',
    BET_INFO_SUB_GAMEPLAY: 'Game Play',
    BET_INFO_BET_LIMIT: 'Minimum Bet',
    BET_INFO_BET_LIST: 'Maximum Bet',
    BET_INFO_INSTALLMENT_LIMIT: 'Maximum Installment Bet',
    BET_INFO_MODAL_TITLE: 'Bet Table',
    BET_INFO_HEADER_DATE: 'Date',
    BET_INFO_HEADER_AMOUNT: 'Amount',
    BET_INFO_HEADER_WITHDRAW: 'Withdraw',
    BET_INFO_HEADER_DEPOSIT: 'Deposit',
    BET_INFO_HEADER_TRANSFER: 'Games Transfer',
    BET_INFO_HEADER_WIN_LOST: 'Win Lost',
    BET_INFO_HEADER_WITHDRAW_CHARGES: 'Withdraw Charges',
    BET_INFO_HEADER_TOP_UP_CHARGES: 'Deposit Charges',
    MORE_GAMES: 'More Games',
    AUXYSCPK10: 'AUXYSCPK10',
    AUXYSSC: 'AUXYSSC',
    HLSXSSC: 'HLSXSSC',
    SHORTCUT_SELECT_BET: 'Shortcut Select Bet',
    SHORTCUT_INPUT_PLACEHOLDER: 'Based on Number=Amount format, space separated for multiple entries.',
    ODDS_TYPE: 'Odds Type',
    SAFETY_CENTER_SETTINGS: 'Settingss',
    SAFETY_CENTER_BANKCARD: 'Bank Card',
    SAFETY_CENTER_COMPLETE_BUTTON: 'Complete',
    SAFETY_CENTER_UNBIND_BUTTON: 'Unbind Phone',
    SAFETY_CENTER_BIND_BUTTON: 'Bind Phone',
    SAFETY_CENTER_BASIC_INFO: 'Basic Information',
    SAFETY_CENTER_FAMILY_NAME: 'Real Name',
    SAFETY_CENTER_FAMILY_NAME_PLACEHOLDER: 'Real Name must be the same as bank registered, else transactions will fail',
    SAFETY_CENTER_FAMILY_NAME_EMPTY_ERROR: 'Please fill in Real Name',
    SAFETY_CENTER_GENDER_EMPTY_ERROR: 'Please fill in Gender',
    SAFETY_CENTER_DOB_ERROR: 'Please fill in date of birth',
    SAFETY_CENTER_GENDER: 'Gender',
    SAFETY_CENTER_GENDER_MALE: 'Male',
    SAFETY_CENTER_GENDER_FEMALE: 'Female',
    SAFETY_CENTER_DATE_OF_BIRTH: 'Date of Birth',
    SAFETY_CENTER_ACCOUNT_SAFETY: 'Account Security',
    SAFETY_CENTER_SAFEPHONE: 'Mobile Number',
    SAFETY_CENTER_SAFEPHONE_PLACEHOLDER: 'Unable to edit after SMS is sent',
    SAFETY_CENTER_SAFEPHONE_ERROR: 'Please enter phone number',
    SAFETY_CENTER_VALID_SAFEPHONE_ERROR: 'Please enter valid phone number',
    SAFETY_CENTER_SAFEPHONE_VERICODE_ERROR: 'Please enter valid OTP',
    SAFETY_CENTER_SAFEPHONE_VERICODE: 'Get Verification SMS',
    SAFETY_CENTER_SAFEPHONE_VERICODE_PLACEHOLDER: 'Verification Code',
    SAFETY_CENTER_SAFEPASS: 'Old Passord',
    SAFETY_CENTER_SAFEPASS_PLACEHOLDER: 'Please enter old password',
    SAFETY_CENTER_OLD_SAFEPASS_EMPTY_ERROR: 'Please enter old password',
    SAFETY_CENTER_NEW_SAFEPASS_EMPTY_ERROR: 'Please enter new password',
    SAFETY_CENTER_CONFIRM_SAFEPASS_EMPTY_ERROR: 'Please enter confirm password',
    SAFETY_CENTER_SAFEPASS_MISMATCH_ERROR: 'Passwords do not match',
    SAFETY_CENTER_SAFEPASS_COMBINATION_ERROR: 'Password must be 6 to 20 alphanumeric',
    SAFETY_CENTER_FORGOT_PASSWORD: 'Forgot Password',
    SAFETY_CENTER_NEW_SAFEPASS: 'New Password',
    SAFETY_CENTER_NEW_SAFEPASS_PLACEHOLDER: 'Please enter 8-12 alpha-numeric characters',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS: 'Confirm Password',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS_PLACEHOLDER: 'Please confirm password',
    SAFETY_CENTER_GIFT_ADDRESS: 'Gift Receipt Address',
    SAFETY_CENTER_GIFT_RECIPIENT: 'Recipient Name',
    SAFETY_CENTER_GIFT_RECIPIENT_PLACEHOLDER: 'Please enter recipient name',
    SAFETY_CENTER_GIFT_RECIPIENT_EMPTY_ERROR: 'Please enter Recipient Name',
    SAFETY_CENTER_GIFT_PHONE_EMPTY_ERROR: 'Please enter mobile number',
    SAFETY_CENTER_GIFT_AREA_EMPTY_ERROR: 'Please enter Province City District',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS_EMPTY_ERROR: 'Please enter Detailed Address',
    SAFETY_CENTER_GIFT_PHONE: 'Mobile Number +86',
    SAFETY_CENTER_GIFT_PHONE_PLACEHOLDER: 'Please enter mobile number',
    SAFETY_CENTER_GIFT_AREA: 'Province City District',
    SAFETY_CENTER_GIFT_AREA_PLACEHOLDER: 'Please enter province city district',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS: 'Detailed Address',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS_PLACEHOLDER: 'Please enter detailed address',
    HX: 'HX',
    LP: 'LP',
    DT: 'DT',
    DTSZ: 'DTSZ',
    INPUT_DMSM: 'INPUT_DMSM',
    DT_TEXT: 'DT_TEXT',
    SAFETY_SETTINGS: 'Settings',
    CASHIER: 'TopUp/Withdrawal',
    TRANSFER_BALANCE: 'Balance Transfer',
    BET_RECORDS: 'Bet Records',
    TRANSACTIONS_RECORDS: 'Transaction Records',
    ANNOUNCEMENT_CENTER: 'Announcement Center',
    PAYMENT_TYPE: 'Payment Type',
    TOP_UP_TYPE: 'Top up type',
    TOP_UP_METHOD: 'Top up Method',
    SELECT_WALLET: 'Select Wallet',
    CONFIRM_TOP_UP: 'Confirm Top Up',
    TOP_UP_CATEGORY_WEB_BANK_PAY: 'Web Bank Payment',
    TOP_UP_CATEGORY_ALIPAY_QR: 'AliPay QR',
    TOP_UP_CATEGORY_FAST_PAY: 'FAST Pay',
    TOP_UP_CATEGORY_CRYPTO_PAY: 'Virtual Pay',
    TOP_UP_CATEGORY_BANK_CARD: 'Credit Card',
    TOP_UP_CATEGORY_ALIPAY_TRANSFER: 'AliPay Transfer',
    TOP_UP_CATEGORY_UNION_SHORTCUT: 'Union Transfer',
    TOP_UP_CATEGORY_CLOUD_PAY: 'Cloud Pay',
    TOP_UP_CATEGORY_BANK_CARD_TRANSFER: 'Bank Card Transfer',
    TOP_UP_CATEGORY_WECHAT_PAY: 'Wechat Pay',
    TOP_UP_CATEGORY_QQ_PAY: 'QQ Pay',
    TOP_UP_CATEGORY_OTHERS_PAY: 'Other Payment',
    TOP_UP_CATEGORY_OFFLINE_PAY: 'Offline Payment',
    TOP_UP_NAME: 'Top Up Name',
    CRYPTO_TYPE: 'Crypto Type',
    TOP_UP_MIN: 'Minimum',
    TOP_UP_MAX: 'Maximum',
    TOP_UP_TRANSACTION_FEES_DESC: 'Transation Fees: X%',
    ORDER_ID_GENERATED: 'Order Generated',
    ORDER_ID: 'Order Id',
    WALLET_NAME: 'Wallet Name',
    WALLET_ADDRESS: 'Wallet Address',
    TRANSACTION_QUANTITY: 'Transaction Quantity',
    TOP_UP_REMINDER: 'When top up is successful, account will be reflected with the latest amount. If you have question, feel free to contact customer service.',
    COMPLETE_TOP_UP_ORDER: 'Complete Top Up Order',
    BANK_CARD_BANK: 'Issuing Bank',
    BANK_CARD_ADDRESS: 'Issuing Bank Address',
    TOP_UP_BANK_NUM: 'Bank Number',
    SCAN_ENDPOINT: 'Scan Endpoint',
    ACCOUNT_KEYCODE: 'Account Keycode',
    ATTENTION: 'Attention',
    TOP_UP_ATTENTION_TEXT: 'Top up remarks must include top id, we will not be responsible if the top id did not get reflected from the backend',
    COPY: 'Copy',
    TOP_UP_USER_REAL_NAME: 'Real Name',
    PLEASE_ENTER_REAL_NAME: 'Please enter real name',
    PLEASE_ENTER_NAME: 'Please enter name',
    PLEASE_ENTER_MIN_AND_MAX_AMOUNT: 'Please enter amount X RMB ~ Y RMB',
    PLEASE_ENTER_VALID_AMOUNT: 'Please enter valid amount',
    CURRENCY_EXCHANGE: 'Currency Exchange',
    TOP_UP_ISSUEING_BANK: 'Top Up Issueing Bank',
    REMINDER: 'Reminder',
    EXCHANGE_RATE: 'Exchange Rate',
    PRESET_AMOUNT: 'Preset Amount',
    ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED: 'Are you sure your top up has succeed?',
    CANCEL_TOP_UP_ORDER: '取消订单',
    GAMES: 'Games',
    THIRD_PARTY_SETTLED: 'Settled',
    THIRD_PARTY_UNSETTLE: 'Unsettle',
    OTHERS: 'Others',
    STATUS: 'Status',
    TO: 'To',
    SEARCH: 'Search',
    GAME_NAME: 'Game Name',
    TOTAL_BET_RECORDS_COUNT: 'Total bet records',
    EFFECTIVE: 'Effective',
    CURRENT_PAGE: 'Current Page',
    EFFECTIVE_AMOUNT: 'Effective Amount',
    WIN_LOSE_RESULT: 'Win/Lose Result',
    TOTAL_COUNT: 'Total Count',
    CONTENT: 'Content',
    CENTER_WALLET: 'CENTER_WALLET',
    LOCK_WALLET: 'LOCK_WALLET',
    AUTO_TRANSFER: 'AUTO_TRANSFER',
    AUTO_TRANSFER_TIPS: 'AUTO_TRANSFER_TIPS',
    ONE_STEP_RECYCLE: 'ONE_STEP_RECYCLE',
    RECYCLE: 'RECYCLE',
    TRANSFER_AMOUNT: 'TRANSFER_AMOUNT',
    INPUT_TRANSFER_AMOUNT: 'INPUT_TRANSFER_AMOUNT',
    INSTANT_TRANSFER: 'INSTANT_TRANSFER',
    REFRESH: 'Refresh',
    DAN: 'Dan',
    CURRENT_PAGE_TOTAL: 'Current Page Total',
    BET_ITEM_DETAILS: 'Bet Item Details',
    BET_ITEM_CONTENT: 'Bet Item Content',
    BET_ITEM_COUNT: 'Bet Item Count',
    WINNING_BET_ITEM_COUNT: 'Winning Bet Item Count',
    WINNING_AMOUNT: 'Winning Amount',
    WINNING_PER_BET: 'Winning Per Bet',
    BANKCARD_OVER_BANKCARD_LIMIT: 'Please remove card and try again',
    BANKCARD_INVALID_FIELDS: 'Please change and try again',
    BANKCARD_ADD_CARD_HEADER: 'Add Bank Card',
    BANKCARD_ADD_CARD_CARD_NAME: 'Card Name',
    BANKCARD_ADD_CARD_CARD_NAME_PLACEHOLDER: 'Please enter card name',
    BANKCARD_ADD_CARD_CARD_NAME_TIPS: 'In order for your funds to arrive in the account quickly, please make sure that the name you fill in is consistent with the name of the bank card account',
    BANKCARD_ADD_CARD_CARD_BANK: 'Issuing Bank',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER: 'Card Number',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER_PLACEHOLDER: 'Enter card number',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER_TIPS: 'Please check the bank card number carefully. If the card number is incorrect, the funds will not be credited to the account.',
    BANKCARD_ADD_CARD_CARD_BANK_ADDRESS: 'Bank address',
    BANKCARD_ADD_CARD_CARD_BANK_ADDRESS_PLACEHOLDER: 'Enter bank address',
    BANKCARD_ADD_CARD_CARD_SAFEPASS: 'Withdraw Password',
    BANKCARD_ADD_CARD_CARD_SAFEPASS_PLACEHOLDER: 'Enter withdraw password',
    BANKCARD_ADD_CARD_COMPLETE: 'Complete',
    BANKCARD_CARD_LIST_HEADER: 'Bank Card List',
    WALLET_DETAIL: 'Wallet Detail',
    WITHDRAWAL_TYPE: 'Withdrawal Type',
    SPEED_WITHDRAWAL: 'SPEED_WITHDRAWAL',
    GENERAL_WITHDRAWAL: 'GENERAL_WITHDRAWAL',
    USDT_WITHDRAWAL: 'USDT_WITHDRAWAL',
    WITHDRAWAL_AMOUNT: 'WITHDRAWAL_AMOUNT',
    CONFIRM_WITHDRAWAL: 'WITHDRAWAL_AMOUNT',
    INPUT_AMOUNT: 'INPUT_AMOUNT',
    BANK_CARD: 'Bank Card',
    ADD_BANK_CARD: 'ADD_BANK_CARD',
    CONFIRM_WITHDRAWAL_WARNING: 'CONFIRM_WITHDRAWAL_WARNING',
    ALL_TYPES: 'All Types',
    ALL_STATUS: 'All Status',
    SERIAL_NUM: 'S/N',
    DIVIDEND: 'Dividend',
    CASH_REBATE: 'Cash Rebate',
    PROMOTIONS: 'Promotions',
    MANUAL_TOP_UP: 'Manual Top Up',
    TRANSFER: 'Transfer',
    UNLOCK_AMOUNT: 'Unlock Amount',
    LOCK_AMOUNT: 'Lock Amount',
    FINANCIAL_TOP_UP: 'Financial Top Up',
    FINANCIAL_DEDUCT: 'Finanaicl Deduct',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    PENDING: 'Pending',
    PENDING_APPROVAL: 'Pending Approval',
    CLOSE: 'Close',
    JSKL10: 'JSKL10',
    RETURN: 'Return',
    BET_REPORT_DETAIL: 'Bet Report Detail',
    SUM: 'Sum',
    ARE_YOU_SURE_TO_DELETE_BANK_CARD: 'Are you sure to delete bank card?',
    PLEASE_ENTER_SAFE_PASS: 'Please enter safe password',
    UNBINDED_CARD_ERROR_MESSAGE: 'Please add cart before continue.',
    BANKCARD_NAME_ERROR: 'Please enter holder name',
    BANKCARD_BANK_ERROR: 'Please select bank',
    BANKCARD_NUMBER_ERROR: 'Please enter card number',
    BANKCARD_ADDRESS_ERROR: 'Please bank address',
    BANKCARD_PASSWORD_ERROR: 'Please enter password',
    NOTIFICATION: 'Notification',
    NEWS: 'News',
    FEEDBACK: 'Feedback',
    TITLE: 'Title',
    TIME: 'Time',
    UNREAD: 'Unread',
    READ: 'Read',
    NOTIFICATION_DETAIL: 'Notification Detail',
    SUGGESTION_FEEDBACK: 'Suggestion Feedback',
    FINANICAL_PROBLEM: 'Financial Problem',
    TECHNICAL_PROBLEM: 'Technical Problem',
    BUSINESS_CONSULTATION: 'Business Consultation',
    SUGGESTIONS: 'Suggestions',
    SYSTEM_INFORMATION: 'System Information',
    OTHER_QUESTIONS: 'Other Questions',
    SUBMIT: 'Submit',
    PLEASE_DESCRIBE_YOUR_ISSUES: 'Please describe your issue',
    PLEASE_ENTER_TITLE: 'Please enter title',
    PLEASE_ENTER_TYPE: 'Please select type',
    PLEASE_ENTER_CONTENT: 'Please enter content',
    CHANGE_PASSWORD_OLD_PASSWORD: 'Password',
    CHANGE_PASSWORD_NEW_PASSWORD: 'New Password',
    CHANGE_PASSWORD_CONFIRM_PASSWORD: 'Confirm Password',
    CHANGE_PASSWORD_PASSWORD_PLACEHOLDER: 'Please enter current password',
    CHANGE_PASSWORD_NEW_PASSWORD_PLACEHOLDER: '6-20 alphanumeric case-sensitive characters',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: 'Please enter confirm password, ensuring no errors',
    CHANGE_PASSWORD_PASSWORD_EMPTY_ERROR: 'Please enter password',
    CHANGE_PASSWORD_NEW_PASSWORD_EMPTY_ERROR: 'Please enter new password',
    CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR: '6-20 alphanumeric case-sensitive characters',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY_ERROR: 'Please enter confirm password',
    CHANGE_PASSWORD_NEW_CONFIRM_PASSWORD_MISMATCHED_ERROR: 'New and Confirm passwords do not match',
    LOTTERY_RESULT_CANCEL: 'Lottery Cancelled',
    DXYFTPK10: 'DXYFTPK10',
    CXYFTPK10: 'CXYFTPK10',
    CQSSC: 'CQSSC',
    COMPLETE: 'Complete',
    BIG_SMALL: 'Big Small',
    ODD_EVEN: 'Odd Even',
    LAST_NUM_BIG_SMALL: 'Last Num Big Small',
    COMBINE_ODD_EVEN: 'Combine Odd Even',
    QUICK_BET_SELECTION: 'Quick bet selection',
    USER_AGREEMENT: 'User Agreement',
    IMPORTANT_NOTICE: 'Important Notice',
    DO_NOT_REMIND_TODAY: 'Do not remind me today',
    CHECK_OUT_DETAIL: 'Check Out Detail',
    CHANGLONG_LIANGMIAN: 'Two Sides',
    CHANGLONG_TEMA: 'Special Number',
    CHANGLONG_CLEAR: 'Clear',
    CHANGLONG_SELECT_ALL: 'Select All',
    CHANGLONG_FREQUENCY_SELECT: 'Frequency Select',
    XYSSCFTSSC: 'XYSSCFTSSC',
    BJFTPK10: 'BJFTPK10',
    CQFTSSC: 'CQFTSSC',
    XYFTFTPK10: 'XYFTFTPK10',
    NO_DATA: 'No Data to Display',
    PAGE: 'page',
    X_AMOUNT_ODDS_CHANGE_ARE_YOU_SURE_TO_UPDATE: 'X bet(s) odds had changed. Do you want to update?',
    UPDATE_IMMEDIATELY: 'Update Immediately',
    CANCEL_IMMEDIATELY: 'Cancel Immediately',
    AUXY20BJK8: 'AUXY20BJK8',
    BET_REPORT_TOTAL: 'Total',
    GD11X5: 'GD11X5',
    GX11X5: 'GX11X5',
    JX11X5: 'JX11X5',
    SD11X5: 'SD11X5',
    DANHAO: 'DANHAO',
    LOTTERY_HAD_CLOSED: 'Lottery had closed',
    NEXT_DRAW_TIME_IS: 'Next draw time is',
    TWHK6: 'TWHK6',
    PK10: 'PK10',
    CQXYNC: 'CQXYNC',
    VOUCHER_REDEMPTION: 'Voucher Redemption',
    VOUCHER_REDEMPTION_EMPTY_ERROR: 'Voucher coda cannot be empty!',
    HE_DAN_SHUANG: 'HE_DAN_SHUANG',
    DA_XIAO_WEI: 'DA_XIAO_WEI',
    SE_BO: 'SE_BO',
    FU_LU_SHOU_XI: 'FU_LU_SHOU_XI',
    PCDD: 'PCDD',
    GXK3: 'GXK3',
    WITHDRAWAL_BANK_CARD_PLACEHOLDER: 'Please select bank card',
    CARD_ENDING: 'Card ending with',
    ERROR_UNSELECTED_BANKCARD: 'Please select a bank card',
    ERROR_EMPTY_AMOUNT: 'Please enter withdraw amount',
    ERROR_AMOUNT_MORE_THAN: 'Amount cannot be more than',
    HN5MSSC: 'HN5MSSC',
    BJK3: 'BJK3',
    XY28KL8: 'XY28KL8',
    SGFTPK10: 'SGFTPK10',
    XYFTPK10: 'XYFTPK10',
    XYSCFTPK10: 'XYSCFTPK10',
    JSXYFTPK10: 'JSXYFTPK10',
    GDKL10: 'GDKL10',
    BJK8: 'BJK8',
    JJSSCPK10: 'JJSSCPK10',
    JJSSSC: 'JJSSSC',
    XJSSC: 'XJSSC',
    JAUXY5SSC: 'JAUXY5SSC',
    JAUXY8KL10: 'JAUXY8KL10',
    SNPHK6: 'SNPHK6',
    CCHK6: 'CCHK6',
    SNPFCHK6: 'SNPFCHK6',
    SNPDYHK6: 'SNPDYHK6',
    JAUXYPK10: 'JAUXYPK10',
    YHFSPK10: 'YHFSPK10',
    JXJ3SSC: 'JXJ3SSC',
    YHFSFTPK10: 'YHFSFTPK10',
    YHFSSSC: 'YHFSSSC',
    JXJ5SSC: 'JXJ5SSC',
    JKLFTPK10: 'JKLFTPK10',
    YH3DK3: 'YH3DK3',
    TJSSC: 'TJSSC',
    YHKL10: 'YHKL10',
    HYJSSCPK10: 'HYJSSCPK10',
    YHHK6: 'YHHK6',
    HYJSSSC: 'HYJSSSC',
    IGSSC: 'IGSSC',
    IGPK10: 'IGPK10',
    AUXY8KL10: 'AUXY8KL10',
    CJ_11X5JSC: '11X5JSC',
    JSNSSC: 'JSNSSC',
    JSSCNPK10: 'JSSCNPK10',
    USASCPK10: 'USASCPK10',
    AUXYPK10: 'AUXYPK10',
    AUXY5SSC: 'AUXY5SSC',
    WNSK3: 'WNSK3',
    FJK3: 'FJK3',
    JSUK3: 'JSUK3',
    SHK3: 'SHK3',
    JLK3: 'JLK3',
    JJSHK6: 'JJSHK6',
    FJ36X7: 'FJ36X7',
    FJ31X7: 'FJ31X7',
    CURRENT_LOTTERY: 'CURRENT_LOTTERY',
    CUSTOMIZE: 'CUSTOMIZE',
    DSNJSSCPK10: 'DSNJSSCPK10',
    CHANGLONG_RANK: 'CHANGLONG_RANK',
    CAIZHONG: 'CAIZHONG',
    GE: 'Ge',
    _PK10_SERIES: 'PK10_SERIES',
    _SSC_SERIES: 'SSC_SERIES',
    _HK6_SERIES: 'HK6_SERIES',
    _KL10_SERIES: 'KL10_SERIES',
    _11X5_SERIES: '11X5_SERIES',
    _GXK10_SERIES: 'GXK10_SERIES',
    _F36X7_SERIES: 'F36X7_SERIES',
    _BJK8_SERIES: 'BJK8_SERIES',
    _PCDD_SERIES: 'PCDD_SERIES',
    _F31X7_SERIES: 'F31X7_SERIES',
    ACCOUNT_LOGGED_IN_ELSEWHERE_MESSAGE: 'Account has been logged in elsewhere',
    EXCHANGE_RATE_FRONT: 'Against RMB，current exhange rate is',
    EXCHANGE_RATE_BACK: 'Applied exchange rates will only be show on statement',
    TOPUP_NAME_PLACEHOLDER: 'Depositor actual name',
    FORECAST: 'Forecast',
    OTHER_AMOUNT: 'Other Amount',
    OTHER_AMOUNT_PLACEHOLDER: 'Please enter amount',
    AMOUNT_ERROR: 'Entered amount must be more than 0',
    NAME_ERROR: 'Depositors name cannot be empty',
    TOTAL_AMOUNT: 'Total Amount',
    TOP_UP_REMINDER_WARM_TIPS: 'Tips',
    QR_CODE: 'QR Code',
    MY_WALLET: 'My Wallet',
    GAME_LOBBY: 'Game Lobby',
    CO_OP: 'Cooperation',
    FAQ: 'FAQ',
    INBOX: 'Inbox',
    CHANGE_THEME: 'Change Theme',
    MOBILE_LOGOUT: 'Logout',
    TEST_PLAYER: 'Visitor',
    PROFILE_DRAWER_REGISTER: 'Register',
    WAIT_X_BEFORE_REQUEST: 'X seconds before requesting',
    GAME_MENU: 'Game Menu',
    THIRD_PARTY_GAMES: 'Third Party Games',
    TRANSFER_OUT: 'Transfer Out',
    TRANSFER_IN: 'Transfer In',
    PLEASE_SELECT_TRANSFER_OUT_ACCOUNT: 'Please select tranfer out account',
    PLEASE_SELECT_TRANSFER_IN_ACCOUNT: 'Please select transfer in account',
    ENTER_GAME: 'Enter Game',
    BALANCE: 'Balance',
    UNSETTLED_AMOUNT: 'UNSETTLED_AMOUNT',
    CLOSE_PAN: 'Close Pan',
    GYH: 'GYH',
    LH: 'LH',
    SAFE_PASS_SUBHEADER: 'For account security, please modify the withdrawal code regularly, the password is a combination of 6-20 letters + numbers',
    RESET_PASSWORD_SUBHEADING: 'Please enter a new password to reset the password',
    CHANGE_PASSWORD_SUBHEADING: 'For account security, please change your password regularly, the password is a combination of 8-20 letters + numbers',
    NEW_GIFT_ADDRESS: 'New address',
    DETAIL: 'Detail',
    BIND_CARD_SUBHEADER: 'In order to ensure the safety of the funds in the account, the name of the withdrawal person cannot be changed after filling in. If you need to change it, please contact customer service',
    BIND_PHONE_HEADER: 'Unbind Phone',
    UNBIND_PHONE_HEADER: 'Bind Phone',
    BIND_PHONE_SUBHEADER: '* For the safety of your account information and funds, please bind your normal mobile phone number!',
    YXXZH: 'YXX/ZH',
    _14LH: '1-4LH',
    SECONDS_TO_RETRY: 'seconds then try again',
    BANK_CARD_DETAIL: 'Bank Card Detail',
    MOBILE_LOGOUT_REMINDER: 'Mobile Logout Reminder',
    QZHS: 'QZHS',
    ZH_LH: 'ZH_LH',
    COOP_TITLE: 'Co-op',
    COOP_ADVANTAGE: 'Co-op Advantage',
    COOP_AGREEMENT: 'Co-op Agreement',
    AUTO_TRANSFER_HEADER: 'Games auto transfer',
    TRANSFER_ALL: 'Transfer All',
    GAME_WALLETS: 'Game Wallet',
    GAME_WALLETS_RIGHT: 'One-click transfer, one step in place',
    ONE_KEY_TRANSFER_TO: 'One-click transfer',
    ON: 'On',
    OFF: 'Off',
    TRANSFER_SAME_SELECTED_ERROR: 'Transfer-out and transfer-in cannot be the same',
    MORE_OPTIONS: 'More Options',
    ALL_TRANSACTION: 'All',
    CANCEL_ORDER_TIP: 'Are You Sure To Cancel The Order？',
    TEDAXIAO: 'TEDAXIAO',
    TEHEWEI: 'TEHEWEI',
    ZH_FH_DX_DS: 'ZH_FH_DX_DS',
    CONNECTION_DETAIL: 'Connection Status',
    UNABLE_TO_CONNECT: 'Disconnected',
    NOTICE_DETAIL: 'NOTICE_DETAIL',
    _PROMOTION: '_PROMOTION',
    HAVE_ACCOUNT: 'HAVE_ACCOUNT',
    PLEASE_LOGIN: 'PLEASE_LOGIN',
    BACK_PAGE: 'BACK_PAGE',
    NO_ACCOUNT: 'NO_ACCOUNT',
    PLEASE_REGISTER: 'PLEASE_REGISTER',
    MEMBER_LOGIN: 'Member Login',
    LOW_POPULAR_ACCOUNT_BALANCE: 'Low Popular Balance',
    HIGH_POPULAR_ACCOUNT_BALANCE: 'High Popular Balance',
    RANDOM_NUM: 'Rnd Num',
    RANDOM_NUM_SHORTEN: 'Rnd Num',
    EMAIL_LOGIN: 'Login',
    CUSTMOER_FEEDBACK: 'Customer Feedback',
    CLEAR: 'CLEAR',
    GENERATE: 'GENERATE',
    RESTORE: 'RESTORE',
    GENERATE_NUMBER_BOX: 'Generate Number Box',
    GENERATE_NUMBER: 'Number Generated',
    START: 'START',
    END: 'END',
    LUCKY_NUMBER: 'Lucky Number',
    ADD_ROW: 'Add Row',
    CONFIRM_BET: 'Place Bet',
    FLOP_NUMBER: 'Flop Number',
    HUNDRED: 'Hundred',
    TEN: 'Ten',
    UNIT: 'Unit',
    SEVENTH_PLACE: 'Seventh Place',
    SIXTH_PLACE: 'Sixth Place',
    FIFTH_PLACE: 'Fifth Place',
    FOURTH_PLACE: 'Fourth Place',
    THIRD_PLACE: 'Thrid Place',
    SECOND_PLACE: 'Second Place',
    FIRST_PLACE: 'First Place',
    SPECIAL_PRIZE: '特奖',
  }
};