import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  notificationData: state.memberReducers.balanceLogData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function NotificationModal({ updateModalAlert, notificationData }) {
  const [_data, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(notificationData) !== JSON.stringify(_data)) {
      setData(notificationData);
    }
  }, [notificationData]);

  return (
    <div className="notificationModalWrapper">
      <Row className="modalHeader w-100">
        <Col xs="4" />
        <Col
          className="d-flex justify-content-center align-items-center"
          xs="4"
        >
          {t('NOTIFICATION_DETAIL')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <table cellSpacing="0" cellPadding="0" border="0">
          <tbody>
            <tr>
              <td className="label">标题</td>
              <td>【充值】订单审核失败提示</td>
            </tr>
            <tr>
              <td className="label">时间</td>
              <td>21-06-30 14:18</td>
            </tr>
            <tr>
              <td colSpan="2" className="label">内容</td>
            </tr>
            <tr>
              <td colSpan="2" className="content">您的【充值】订单，订单号：c77a8eca7f354888a3e32859cv044d4c, 交易金额：1（其中，手续费5）。处理结果为：失败。</td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row className="modalFooter p-2">
        <Col xs="12" className="justify-content-center d-flex">
          <Button
            type="button"
            className="confirmBtn"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CONFIRM')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);