import { StatusCodes } from 'http-status-codes';

import { SET_BET_RESULT_LIST, SET_LENG_RE_RESULT } from '../../actionTypes';
import { apiService } from '../../../library';

const setResultBetList = resultList => ({
  type: SET_BET_RESULT_LIST,
  value: resultList,
});

const setLengReResult = lengReResult => ({
  type: SET_LENG_RE_RESULT,
  value: lengReResult,
});

const getResult = (lotteryTime, lotteryType, page = 1, rows = 10, isLengReRequest) => dispatch => {
  apiService.get(`/resultHistory/getResult/${lotteryType}`, new URLSearchParams({
    lotteryTime, page, rows
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      const { list } = data.result || {};
      if (isLengReRequest) {
        const resultItemCount = {};
        list.forEach(item => {
          const openResultArr = item?.openResult ? item.openResult.split(',') : '';
          if (openResultArr) {
            openResultArr.forEach(num => {
              resultItemCount[num] = resultItemCount[num] ? resultItemCount[num] + 1 : 1;
            });
          }
        });
        dispatch(setLengReResult({
          ...data.result,
          resultItemCount
        }));
      }
      return dispatch(setResultBetList(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const GetTop = (lotteryType, top, callback) => () => {
  apiService.get(`/resultHistory/GetTop/${lotteryType}`, new URLSearchParams({
    rows: top
  })).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      return callback(data?.result || []);
    }
  }).catch(error => {
    callback([]);
    Promise.reject(error);
  });
};

const updateGameResult = result => dispatch => (
  dispatch(setResultBetList(result))
);

export default {
  getResult,
  GetTop,
  updateGameResult,
};