import axios from 'axios';

import instance from '../apiServiceInstance';

const { CancelToken } = axios || {};
const cancel = {};

/**
 * @param {string} path
 * @param {string} param
 * @param {object} headers
 * @description Method to call POST through Axios
 */
export const post = (path, param, headers) => {
  if (cancel[path]) {
    cancel[path]();
    cancel[path] = undefined;
  }
  return instance.post(path, param, {
    ...headers,
    cancelToken: new CancelToken(c => {
      cancel[path] = c;
    }),
    withCredentials: true
  });
};

/**
 * @param {string} path
 * @param {string} param
 * @param {object} headers
 * @description Method to call GET through Axios
 */

export const get = (path, param, headers) => {
  if (cancel[path]) {
    cancel[path]();
    cancel[path] = undefined;
  }
  return instance.get(`${path}${param === undefined ? '' : `?${param}`}`, {
    ...headers,
    cancelToken: new CancelToken(c => {
      cancel[path] = c;
    }),
    withCredentials: true
  });
};

/**
 * @param {string} path
 * @param {string} data
 * @param {object} headers
 * @description Method to call ApiDelete through Axios
 */
export const apiDelete = (path, data, headers) => instance.delete(`${path}`, {
  headers, data
});

/**
 * @param {string} requests
 * @description Method to call a series of axios call through Axios
 */
export const all = requests => instance.all(requests);

export default {
  post,
  get,
  apiDelete,
  all
};