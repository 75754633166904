import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Web/Content/DataTable';
import Label from '../../Label';
import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function LatestAnnouncementModal({ platform, updateModalAlert, data: { latestNews } }) {
  const [_latestNews, setLatestNews] = useState([]);
  const [_doNotRemind, setDoNotRemind] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (latestNews) {
      setLatestNews(latestNews);
    }
  }, [latestNews]);

  return (
    <div className={`latestAnnouncementWrapper${platform}`}>
      <Row className="modalHeader">
        最新公告
      </Row>
      <Row className="modalContent p-3">
        <DataTable
          tableHeaderTitle={
          [

            {
              label: t('CONTENT'),
              className: 'contentColumn'
            }, {
              label: t('TIME'),
              className: 'timeColumn'
            }]
        }
          tableData={_latestNews || []}
          tableBodyRowProperty={
          [
            data => (
              <div className="text-left text-break p-2">
                <Label value={data.content} />
              </div>
            ),
            data => (<Label value={moment.unix(data.createDate).format('YYYY-MM-DD HH:mm')} />),
          ]
        }
        />
      </Row>
      <Row className="modalFooter p-3">
        <Col xs="6" className="justify-content-start align-items-center pl-2 d-flex">
          <div className="doNotRemindTitle">
            <Checkbox checked={_doNotRemind} onChange={e => setDoNotRemind(e.target.checked)}>{t('DO_NOT_REMIND_TODAY')}</Checkbox>
          </div>
        </Col>
        <Col xs="6" className="justify-content-center d-flex">
          <Button
            type="button"
            className="acknowledgeButton p-0 mr-2"
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond}`;
              }
              updateModalAlert({
                visible: false
              });
            }}
          >
            {t('MODAL_ACKNOWLEDGE')}
          </Button>
          <Button
            type="button"
            className="detailButton p-0"
            onClick={() => {
              if (_doNotRemind) {
                const balanceSecond = moment().endOf('day').unix() - moment().unix();
                document.cookie = `doNotRemind=${_doNotRemind}; max-age=${balanceSecond};Path=/`;
              }
              history.push({
                pathname: 'announcement',
                state: {
                  type: 'news'
                }
              });
              updateModalAlert({
                visible: false
              });
            }}
          >
            {t('CHECK_OUT_DETAIL')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestAnnouncementModal);