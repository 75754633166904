import { useState } from 'react';

import { Button, Input } from 'reactstrap';
import { IoCloseCircleOutline, IoCloseOutline } from 'react-icons/io5';
import { Radio } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, gameActions } from '../../../redux/actions';
import Label from '../../Label';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const defaultValues = [5, 10, 20, 50, 100, 500];

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  presetAmountList: state.gameReducers.presetAmountList
});

const mapDispatchToProps = dispatch => ({
  updatePresetAmountList: presetAmountList => dispatch(gameActions.updatePresetAmountList(presetAmountList)),
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function PresetAmountModal({ platform, presetAmountList, updateModalAlert, updatePresetAmountList }) {
  const [enable, setAmountEnable] = useState(true);
  const [_presetAmountList, setPresetAmountList] = useState(presetAmountList);
  const { t } = useTranslation();

  const onSaveClick = () => {
    if (enable) updatePresetAmountList(_presetAmountList);
    else updatePresetAmountList(defaultValues);
    updateModalAlert({
      visible: false
    });
  };

  const onPresetAmountChange = (text, index) => {
    const newPresetAmountList = [..._presetAmountList];
    newPresetAmountList[index] = validateNumber(text) || 0;
    setPresetAmountList(newPresetAmountList);
  };

  return (
    <div className={`presetAmountWrapper ${platform}`}>
      <div className="modalHeader">
        <div
          className="col-6 d-flex align-items-center"
        >
          <Label value={t('QUICK_SELECTION_AMOUNT')} />
        </div>
        <div
          className="col-6 d-flex align-items-center justify-content-end"
        >
          <IoCloseOutline
            className="h4 mb-0 closeIcon"
            onClick={() => updateModalAlert({
              visible: false
            })}
          />
        </div>
      </div>
      <div className={`modalContent ${platform}`}>
        {
          _presetAmountList.map((item, index) => (
            <div className="position-relative" key={`presetAmount_item_${index.toString()}`}>
              <Input
                value={item}
                onChange={({ target: { value: text } }) => {
                  onPresetAmountChange(text, index);
                }}
              />
              <IoCloseCircleOutline className={`amountClear ${platform}`} onClick={() => onPresetAmountChange(0, index)} />
            </div>
          ))
        }
      </div>
      <div className={`modalFooter py-2 ${platform}`}>
        <div className=" d-flex justify-content-center align-items-center mb-2">
          <Radio.Group
            onChange={e => {
              localStorage.setItem('settingChecked', true);
              setAmountEnable(e.target.value);
              if (!e.target.value) {
                setPresetAmountList(defaultValues);
              }
            }}
            value={enable}
          >
            <Radio value>启用</Radio>
            <Radio value={false}>默认</Radio>
          </Radio.Group>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button type="button" onClick={onSaveClick} className="saveBtn p-0">{t('SAVE')}</Button>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PresetAmountModal);