import { useEffect, useRef, useState } from 'react';

import { Button, Col, Row, Table } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, topupActions } from '../../../redux/actions';
import Label from '../../Label';
import { ReactComponent as Tick } from '../../../assets/icon/TopUpIcons/icon_tick.svg';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  checkStatus: transferId => dispatch(topupActions.checkStatus(transferId)),
  verifyTopUp: transferId => dispatch(topupActions.verify(transferId)),
  cancelTopUp: transferId => dispatch(topupActions.cancel(transferId)),
});

function TopUpPanelModal({ platform, updateModalAlert, checkStatus, depositResult, verifyTopUp, cancelTopUp }) {
  const { t } = useTranslation();
  const savedCallback = useRef();
  const [_depositResult, setDepositResult] = useState();

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    const id = setInterval(tick, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    savedCallback.current = () => {
      if (_depositResult && _depositResult.transferId) {
        checkStatus(_depositResult.transferID);
      }
    };
  });

  useEffect(() => {
    if (JSON.stringify(depositResult) !== JSON.stringify(_depositResult)) {
      setDepositResult(depositResult.result);
    }
  }, [depositResult]);

  const onCancelTopUpClick = () => {
    if (_depositResult && _depositResult.transferID) {
      cancelTopUp(_depositResult.transferID);
    }
    updateModalAlert({
      visible: false,
    });
  };

  const onCompleteTopUpClick = () => {
    if (_depositResult && _depositResult.dirHost) {
      if (_depositResult.bankCode && (_depositResult.bankCode === 'USDT_ERC_20' || _depositResult.bankCode === 'USDT_TRC_20')) {
        updateModalAlert({
          visible: false
        });
      }
      const link = _depositResult.transferID ? `${_depositResult.dirHost}/cash/goToPay?transferID=${_depositResult.transferID}` : _depositResult.dirHost;
      return window.open(link, '_blank');
    }
    return updateModalAlert({
      visible: true,
      type: 'confirmation',
      data: {
        message: t('ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED'),
        onConfirmationClick: () => {
          updateModalAlert({
            visible: false,
          });
          verifyTopUp(_depositResult.transferID);
        },
        onCancelClick: () => {
          updateModalAlert({
            visible: true,
            type: 'topUp',
            data: {
              message: depositResult.message,
              result: depositResult.result,
            }
          });
        }
      }
    });
  };

  const copyToClipboard = input => navigator.clipboard.writeText(input);

  return (
    <div className={`topUpPanelWrapper${platform}`}>
      <Row className="modalHeader">
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{
            size: 4, offset: 4
          }}
        >
          {t('ORDER_ID_GENERATED')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent">
        <Row className={`${platform === 'Mobile' && 'mx-0'}`}>
          <Col xs={`${platform === 'Web' ? '7' : '12'}`} className={`${platform === 'Mobile' ? 'mx-0 px-0 successMessage' : 'successMessage py-2 my-3'}`}>
            <Label value={depositResult && depositResult.message} />
          </Col>
        </Row>
        {
          platform === 'Web' && _depositResult && _depositResult.bankCode && (_depositResult.bankCode.includes('QrCode') || _depositResult.bankCode === 'BOUSDTTRC20') && (
            <Row className="my-3">
              <QRCode value={_depositResult.bankAddress} />
            </Row>
          )
        }
        {
          _depositResult && !_depositResult.bankCode && platform === 'Web' && (
            <Row className="my-3">
              <Tick className="successTick" />
            </Row>
          )
        }
        {_depositResult && _depositResult.orderID && !_depositResult.bankCode && (
        <Row className={`${platform === 'Mobile' && 'mx-0'} my-2 orderIdWrapper`}>
          <Label value={`${t('ORDER_ID')}: ${_depositResult && _depositResult.orderID}`} />
        </Row>
        )}
        <Row className={`${platform === 'Mobile' && 'mx-0 px-1'}`}>
          <Table className={`detailTable ${platform === 'Mobile' && 'mb-0'}`}>
            <tbody>
              {
                _depositResult && _depositResult.bankCode && (
                  <>
                    {platform === 'Mobile' && _depositResult && (
                      <tr>
                        <td className="label">
                          <Label value={t('ORDER_ID')} />
                        </td>
                        <td className="value">
                          <Label value={_depositResult.orderID} />
                        </td>
                        <td>
                          <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.orderID)}>复制</div>
                        </td>
                      </tr>
                    )}
                    {
                      _depositResult && _depositResult.bankCode && !_depositResult.bankCode.includes('QrCode') && (
                        <tr>
                          <td className="label">
                            <Label value={`${_depositResult.bankCode === 'BOUSDTTRC20' ? t('WALLET_NAME') : t('TOP_UP_ISSUEING_BANK')}`} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.bankName} />
                          </td>
                          {platform === 'Mobile' && (
                            <td>
                              <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.bankName)}>复制</div>
                            </td>
                          )}
                        </tr>
                      )
                    }
                    <tr>
                      <td className="label">
                        <Label value={`${_depositResult.bankCode === 'BOUSDTTRC20' ? t('WALLET_ADDRESS') : t('TOP_UP_BANK_NUM')}`} />
                      </td>
                      <td className="value">
                        <Label value={_depositResult && (_depositResult.bankCode === 'BOUSDTTRC20' ? _depositResult.bankAddress : _depositResult.bankNumber)} />
                      </td>
                      {platform === 'Mobile' && (
                        <td>
                          <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.bankCode === 'BOUSDTTRC20' ? _depositResult.bankAddress : _depositResult.bankNumber)}>复制</div>
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="label">
                        <Label value={_depositResult && _depositResult.bankCode && _depositResult.bankCode.includes('QrCode') ? t('SCAN_ENDPOINT') : t('TOP_UP_NAME')} />
                      </td>
                      <td className="value">
                        <Label value={_depositResult && _depositResult.bankUName} />
                      </td>
                      {platform === 'Mobile' && (
                        <td>
                          <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.bankUName)}>复制</div>
                        </td>
                      )}
                    </tr>
                    {
                      _depositResult && _depositResult.bankCode && !_depositResult.bankCode.includes('QrCode') && _depositResult.bankCode !== 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('BANK_CARD_ADDRESS')} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.bankAddress} />
                          </td>
                          {platform === 'Mobile' && (
                            <td>
                              <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.bankAddress)}>复制</div>
                            </td>
                          )}
                        </tr>
                      )
                    }
                    {
                      _depositResult.bankCode !== 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('ACCOUNT_KEYCODE')} />
                          </td>
                          <td className="value">
                            <Label value={_depositResult && _depositResult.acKeyCode} />
                          </td>
                          {platform === 'Mobile' && (
                            <td>
                              <div className="p-1 my-1 copyButton" onClick={() => copyToClipboard(_depositResult.acKeyCode)}>复制</div>
                            </td>
                          )}
                        </tr>
                      )
                    }
                    {
                      _depositResult.bankCode === 'BOUSDTTRC20' && (
                        <tr>
                          <td className="label">
                            <Label value={t('TRANSACTION_QUANTITY')} />
                          </td>
                          <td className="value">
                            <Label value={`${_depositResult && _depositResult.actionAmountExt} (${t('EXCHANGE_RATE')}:${_depositResult && _depositResult.actionAmountExtConvertValue}, ${t('PRESET_AMOUNT')}:${_depositResult && _depositResult.actionAmount})`} />
                          </td>
                        </tr>
                      )
                    }
                    {platform === 'Mobile' && _depositResult && _depositResult.bankCode && (_depositResult.bankCode.includes('QrCode') || _depositResult.bankCode === 'BOUSDTTRC20') && (
                      <tr>
                        <td className="label">
                          <Label value={t('QRCode')} />
                        </td>
                        <td>
                          <QRCode value={_depositResult.bankAddress} />
                        </td>
                        <td>
                          <div className="p-1 copyButton" onClick={() => copyToClipboard(_depositResult.bankAddress)}>复制</div>
                        </td>
                      </tr>
                    )}
                  </>
                )
              }
            </tbody>
          </Table>
          {platform === 'Web' && (
            <Row className="attentionMessage my-3">
              <Col xs="1" className="p-0 text-center">
                <Label value={`${t('ATTENTION')}:`} />
              </Col>
              <Col xs="7" className="p-0 text-center">
                <Label className="attentionText" value={`${t('TOP_UP_ATTENTION_TEXT')}`} />
              </Col>
            </Row>
          )}
        </Row>
        {platform === 'Web' && (
          <Row>
            <Col xs="9" className="reminderMessage py-2 my-3">
              <Label value={t('TOP_UP_REMINDER')} />
            </Col>
          </Row>
        )}
        <Row className={`${platform === 'Mobile' && 'mx-0'}`}>
          <Button className="completeTopUpButton mt-2 mr-3" onClick={onCompleteTopUpClick}>{t('COMPLETE_TOP_UP')}</Button>
          {_depositResult && _depositResult.transferID && (
          <Button className="cancelButton mt-2" onClick={onCancelTopUpClick}>{t('CANCEL_TOP_UP_ORDER')}</Button>
          )}
        </Row>
        {platform === 'Mobile' && (
          <Row className={`${platform === 'Mobile' && 'mx-0 mt-3 topBorder'}`}>
            <Col xs="12" className="reminderMessage py-2 my-2 px-1">
              <span className="reminderWrapper">
                <span className="yellowText">{`${t('TOP_UP_REMINDER_WARM_TIPS')}: `}</span>
                {t('TOP_UP_REMINDER')}
              </span>
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopUpPanelModal);