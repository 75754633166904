import { StatusCodes } from 'http-status-codes';

import { SET_API, SET_CURRENT_PANKOU, SET_MODAL_ALERT, SET_PLATFORM, SET_THEME_COLOR, SET_YI_LOU_COUNT, TOGGLE_GAME_BALANCE_PANEL, TOGGLE_GAME_MENU, TOGGLE_HORIZONTAL, TOGGLE_LENG_RE_BUTTON, TOGGLE_QUICK_BET_BUTTON, TOGGLE_RESULT, TOGGLE_SUB_GAME, TOGGLE_YI_LOU_BUTTON } from '../../actionTypes';
import { apiService } from '../../../library';

const setApi = data => ({
  type: SET_API,
  value: data
});

const setCurrentPankou = data => ({
  type: SET_CURRENT_PANKOU,
  value: data,
});

const setPlatform = platform => ({
  type: SET_PLATFORM,
  value: platform
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const updateHorizontal = () => ({
  type: TOGGLE_HORIZONTAL
});

const updateLengReButton = () => ({
  type: TOGGLE_LENG_RE_BUTTON
});

const updateYiLouButton = () => ({
  type: TOGGLE_YI_LOU_BUTTON
});

const updateSubGame = val => ({
  type: TOGGLE_SUB_GAME,
  value: val
});

const setThemeColor = color => ({
  type: SET_THEME_COLOR,
  value: color
});

const setYiLouCount = count => ({
  type: SET_YI_LOU_COUNT,
  value: count
});
const setQuickBet = val => ({
  type: TOGGLE_QUICK_BET_BUTTON,
  value: val
});

const setGameMenu = val => ({
  type: TOGGLE_GAME_MENU,
  value: val
});

const setGameBalancePanel = val => ({
  type: TOGGLE_GAME_BALANCE_PANEL,
  value: val
});

const setResultVisibility = val => ({
  type: TOGGLE_RESULT,
  value: val
});

const updateResultVisibility = value => dispatch => (
  dispatch(setResultVisibility(value))
);

const updateGameBalancePanel = value => dispatch => (
  dispatch(setGameBalancePanel(value))
);


const updateGameMenuStatus = val => dispatch => (
  dispatch(setGameMenu(val))
);

const updateModalAlert = modal => dispatch => (
  dispatch(setModalAlert(modal))
);

const updatePlatForm = platform => dispatch => (
  dispatch(setPlatform(platform))
);

const updateThemeColor = color => dispatch => (
  dispatch(setThemeColor(color))
);

const toggleHorizontal = () => dispatch => (
  dispatch(updateHorizontal())
);

const toggleSubGame = value => dispatch => (
  dispatch(updateSubGame(value))
);

const updateYiLouCount = value => dispatch => (
  dispatch(setYiLouCount(value))
);

const updateQuickBetToggle = value => dispatch => (
  dispatch(setQuickBet(value))
);

const testApi = () => dispatch => {
  apiService.get('/SampleData/WeatherForecasts').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setApi(data));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  testApi,
  updateModalAlert,
  updatePlatForm,
  updateThemeColor,
  updateYiLouCount,
  updateLengReButton,
  updateYiLouButton,
  toggleHorizontal,
  toggleSubGame,
  updateQuickBetToggle,
  updateGameMenuStatus,
  updateGameBalancePanel,
  setCurrentPankou,
  updateResultVisibility
};