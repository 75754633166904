import axios from 'axios';

import { SET_MODAL_ALERT, USER_LOGOUT } from '../../redux/actionTypes';
import { store } from '../../redux';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : `${window.location.protocol}//${window.location.hostname}/api`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

instance.interceptors.request.use(
  config => {
    const storeState = store.getState();
    const { platform } = storeState.appReducers || {};
    if (platform === 'Mobile') {
      config.baseURL = `${config.baseURL}/Mobile/`;
    }
    return config;
  },
  error => {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      return;
    }
    if (error.response) {
      const {
        data,
        status,
      } = error.response || {};

      const { msg } = data || {};

      if (status === 401 && msg) {
        return store.dispatch({
          type: SET_MODAL_ALERT,
          value: {
            visible: true,
            type: 'confirmation',
            data: {
              message: '你的账号已在其他地方登入',
              confirmText: '知道了',
              showCancelButton: false,
              onConfirmationClick: () => {
                store.dispatch({
                  type: USER_LOGOUT
                });
              }
            }
          }
        });
      }
    }
    if (error.code === 'ECONNABORTED') {
      return store.dispatch({
        type: SET_MODAL_ALERT,
        value: {
          visible: true,
          type: 'confirmation',
          data: {
            message: '请求已超时，请重新尝试',
            confirmText: '知道了',
            showCancelButton: false,
            onConfirmationClick: () => {
              store.dispatch({
                type: SET_MODAL_ALERT,
                value: {
                  visible: false,
                }
              });
            }
          }
        }
      });
    }
    return Promise.reject(error);
  }
);

export default instance;