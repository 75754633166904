import { StatusCodes } from 'http-status-codes';

import { SET_MODAL_ALERT, SET_WITHDRAWAL_INFO } from '../../actionTypes';
import { apiService } from '../../../library';

const setWithdrawalInfo = withdrawalnfo => ({
  type: SET_WITHDRAWAL_INFO,
  value: withdrawalnfo,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const getInfo = () => dispatch => {
  apiService.get('/withdraw/getInfo').then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(setWithdrawalInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const save = (acAmount, bankId, safePass, successCallback) => dispatch => {
  apiService.post('/withdraw/save', {
    acAmount, bankId, safePass
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      dispatch(getInfo());
      if (successCallback) successCallback();
      dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

export default {
  getInfo,
  save
};