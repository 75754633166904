import { DELETE_HENEI_IN_CART, DELETE_ITEM_IN_CART, INSERT_HENEI_TO_CART, INSERT_ITEM_TO_CART, RESET_ITEM_IN_CART, UPDATE_ENTIRE_CART, UPDATE_PRESET_AMOUNT } from '../../actionTypes';

const initialState = {
  cart: undefined,
  heneiCart: {}
};

const appReducers = (state = initialState, action) => {
  const tempCart = {
    ...state.cart
  };

  const tempHeneiCart = {
    ...state.heneiCart
  };

  switch (action.type) {
    case INSERT_ITEM_TO_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          ...action.value
        }
      };

    case INSERT_HENEI_TO_CART:
      const { keyCode, item } = action.value;
      if (!tempHeneiCart[keyCode]) {
        tempHeneiCart[keyCode] = [];
      }
      tempHeneiCart[keyCode].push(item);
      return {
        ...state,
        heneiCart: tempHeneiCart // Update heneiCart state
      };

    case DELETE_ITEM_IN_CART:
      delete tempCart[action.value];
      return {
        ...state, cart: tempCart
      };

    case DELETE_HENEI_IN_CART:
      const { keyCode: removeKeyCode, itemIndex } = action.payload;
      if (tempHeneiCart[removeKeyCode]) {
        tempHeneiCart[removeKeyCode].splice(itemIndex, 1);
        if (tempHeneiCart[removeKeyCode].length === 0) {
          delete tempHeneiCart[removeKeyCode];
        }
      }
      return {
        ...state, heneiCart: tempHeneiCart
      };

    case RESET_ITEM_IN_CART:
      return {
        ...state,
        cart: initialState.cart,
        heneiCart: initialState.heneiCart
      };

    case UPDATE_PRESET_AMOUNT:
      Object.keys(tempCart).forEach(item => {
        tempCart[item].amount = action.value;
      });
      Object.keys(tempHeneiCart).forEach(key => {
        tempHeneiCart[key].forEach(entry => {
          entry.item.amount = action.value;
        });
      });
      return {
        ...state,
        cart: tempCart,
        heneiCart: tempHeneiCart
      };

    case UPDATE_ENTIRE_CART:
      return {
        ...state, cart: action.value
      };

    default:
      return state;
  }
};

export default appReducers;