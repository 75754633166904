import { useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';


const mapStateToProps = state => ({
  platform: state.appReducers.platform,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function MobileOnlineStatusRadioListModal({ data, value, updateModalAlert }) {
  const { t } = useTranslation();
  const [_selectedValue, setSelectedValue] = useState(value || {});
  const selectItem = newItem => {
    setSelectedValue(newItem);
    window.location.href = newItem.domain;
  };

  return (
    <div className="mobileOnlineRadioListWarpper">
      <div className="px-0 text-white modalHeader">
        {t('PLEASE_SELECT')}
      </div>
      <div className="modalBodyWrapper">
        {data && data.map((item, index) => (
          <Row
            key={`item_list_${index.toString()}`}
            className={`${(!item.time || item.time === '99999') ? 'disableClass' : ''}`}
            onClick={() => {
              if (item.time && item?.time !== '99999') {
                selectItem(item);
              }
            }}
          >
            <Col className="d-flex justify-content-start align-items-center col-6">
              {item?.line}
            </Col>
            <Col className="d-flex p-0 m-0 justify-content-end align-items-center cpl-4 col">
              {`${(!item.time || item.time === '99999') ? t('UNABLE_TO_CONNECT') : `${item.time}ms`}`}
            </Col>
            <Col className="d-flex justify-content-center align-items-center col-2">
              <div className="radioButtonItem d-flex justify-content-center align-items-center">
                {
                  ((_selectedValue.line !== undefined) && _selectedValue.line === item.line) ? <IoRadioButtonOn className="h4" /> : <IoRadioButtonOff className="h4" />
                }
              </div>
            </Col>
          </Row>
        ))}
      </div>
      <div className="modalFooter d-flex justify-content-center mx-0 py-2">
        <Button
          className="button py-2"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
          }}
        >
          {t('CLOSE')}
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileOnlineStatusRadioListModal);