import { useEffect, useState } from 'react';

import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Logo from '../../../assets/logo/logo.png';

import { appActions, memberActions } from '../../../redux/actions';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  themeColor: state.appReducers.themeColor,
  captchaData: state.memberReducers.captchaData,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  getCaptcha: () => dispatch(memberActions.getCaptcha()),
  login: (username, password) => dispatch(memberActions.login(username, password)),
  register: (username, password, affCode, captcha, safeCode, safePhone) => dispatch(memberActions.register(username, password, affCode, captcha, safeCode, safePhone)),
});

function LoginAndRehisterModal({ themeColor, updateModalAlert, captchaData, getCaptcha, login, register }) {
  const { t } = useTranslation();

  const [_username, setUsername] = useState();
  const [_password, setPassword] = useState();
  const [_captcha, setCaptcha] = useState();
  const [captchaImage, setCaptchaImage] = useState('');

  const [pageType, setPageType] = useState('login');

  const [account, setaccount] = useState('');
  const [password, setRegisterpassword] = useState('');
  const [rePassword, setrePassword] = useState('');
  const [code, setcode] = useState('');
  const [affCode, setaffCode] = useState('');

  useEffect(() => {
    if (captchaData && captchaData.base64) {
      setCaptchaImage(`data:image/jpg;base64,${captchaData.base64}`);
    } else {
      getCaptcha();
    }
  }, [captchaData]);

  const onCaptchaClick = () => {
    getCaptcha();
  };

  const onLogin = () => {
    updateModalAlert({
      visible: true,
      type: 'loading',
    });
    login(_username, _password, _captcha);
  };

  const onRegister = () => {
    updateModalAlert({
      visible: true,
      type: 'loading',
    });
    register(account, password, affCode, code);
  };


  return (
    <div className={`loginRegisterWarpper theme-${themeColor}`}>
      <div className="loginAndRegister">
        {pageType === 'login'
    && (
    <div className="loginBox">
      <div>
        <div className="loginTitle">
          <img src={Logo} alt="" srcSet="" />
          <div>会员登陆</div>
        </div>
        <div className="loginBoxItem">
          <div>{t('ACCOUNT')}</div>
          <Input value={_username} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setUsername(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{`${t('PASSWORD')}*`}</div>
          <Input type="password" placeholder="请输入您的密码" value={_password} onChange={({ target: { value: text } }) => setPassword(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{`${t('VERIFY_CODE')}*`}</div>
          <Input placeholder="请输入验证码" value={_captcha} onChange={({ target: { value: text } }) => setCaptcha(text)} />
          <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
            <img id="captchaImg" src={captchaImage} alt="" className="w-100" />
          </div>
        </div>
      </div>
      <div className="loginBoxItem">
        <Button className="loginButton" onClick={() => onLogin()}>{t('LOGIN')}</Button>
      </div>
      <div className="loginNoAccount">
        {t('NO_ACCOUNT')}
        ？
        <span onClick={() => setPageType('register')}>{t('PLEASE_REGISTER')}</span>
      </div>
    </div>
    )}
        {pageType === 'register'
    && (
    <div className="loginBox">
      <div>
        <div className="loginTitle">
          <img src={Logo} alt="" srcSet="" />
          <div>会员注册</div>
        </div>
        <div className="loginBoxItem">
          <div>{t('AFFILIATE_CODE')}</div>
          <Input value={affCode} placeholder="请输入您的邀请码" onChange={({ target: { value: text } }) => setaffCode(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{t('ACCOUNT')}</div>
          <Input value={account} placeholder="请输入您的账号" onChange={({ target: { value: text } }) => setaccount(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{`${t('PASSWORD')}*`}</div>
          <Input type="password" placeholder="请输入您的密码" value={password} onChange={({ target: { value: text } }) => setRegisterpassword(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{`${t('CONFIRM_PASSWORD')}*`}</div>
          <Input type="password" placeholder="请输入确认密码" value={rePassword} onChange={({ target: { value: text } }) => setrePassword(text)} />
        </div>
        <div className="loginBoxItem">
          <div>{`${t('VERIFY_CODE')}*`}</div>
          <Input placeholder="请输入验证码" value={code} onChange={({ target: { value: text } }) => setcode(text)} />
          <div className="border-0 w-25" onClick={() => onCaptchaClick()}>
            <img id="captchaImg" src={captchaImage} alt="" className="w-100" />
          </div>
        </div>
      </div>
      <div className="loginBoxItem">
        <Button className="loginButton" onClick={() => onRegister()}>{t('REGISTER')}</Button>
      </div>
      <div className="loginNoAccount">
        {t('HAVE_ACCOUNT')}
        ？
        <span onClick={() => setPageType('login')}>{t('PLEASE_LOGIN')}</span>
        <div onClick={() => setPageType('login')}>
          {t('BACK_PAGE')}
        </div>
      </div>
    </div>
    )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAndRehisterModal);