import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function BetItemDetailModal({ updateModalAlert, data, }) {
  const [_data, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(_data)) {
      setData(data);
    }
  }, [data]);

  return (
    <div className="betItemDetailModalWrapper">
      <Row className="modalHeader w-100">
        <Col xs="4" />
        <Col
          className="d-flex justify-content-center align-items-center"
          xs="4"
        >
          {t('BET_ITEM_DETAILS')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
          />
        </Col>
      </Row>
      <Row className="modalContent p-3">
        <table cellSpacing="0" cellPadding="0" border="0">
          <tbody>
            <tr>
              <td className="label">{t('ORDER_NUMBER')}</td>
              <td>{_data && _data.oddNo}</td>
              <td className="label">{t('BET_TIME')}</td>
              <td>{_data && moment.unix(_data.createDate).format('YYYY-MM-DD HH:mm:ss')}</td>
            </tr>
            <tr>
              <td className="label">{t('INSTALLMENTS_NUM')}</td>
              <td>{`${t('DI')}${_data && _data.installments}${t('INSTALLMENTS')}`}</td>
              <td className="label">{t('ODDS')}</td>
              <td>
                {`${_data && _data.midType}--${_data && _data.displayName}@${_data && _data.odds}`}
              </td>
            </tr>
            <tr>
              <td className="label">{t('TYPE')}</td>
              <td>{_data && _data.lotteryName}</td>
              <td className="label">{t('PAN_KOU')}</td>
              <td>{_data && _data.oddsType}</td>
            </tr>
            <tr>
              <td>{t('BET_ITEM_CONTENT')}</td>
              <td colSpan="3" />
            </tr>
            <tr>
              <td className="label">{t('REPORT_BET_AMOUNT')}</td>
              <td>{_data && _data.bettingAmount}</td>
              <td className="label">{t('BET_ITEM_COUNT')}</td>
              <td>1</td>
            </tr>
            <tr>
              <td className="label">{t('WINNING_BET_ITEM_COUNT')}</td>
              <td>1</td>
              <td className="label">{t('WINNING_AMOUNT')}</td>
              <td>{_data && _data.result}</td>
            </tr>
            <tr>
              <td className="label">{t('REBATE')}</td>
              <td>{`${_data && _data.returnValue}%`}</td>
              <td className="label">{t('WINNING_PER_BET')}</td>
              <td>{_data && _data.result}</td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row className="modalFooter p-2">
        <Col xs="12" className="justify-content-center d-flex">
          <Button
            type="button"
            className="confirmBtn"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CONFIRM')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default connect(null, mapDispatchToProps)(BetItemDetailModal);