import { SET_BALANCE_LOG, SET_BET_LIMIT, SET_CAPTCHA_DATA, SET_USER_DATA, SET_USER_INFO, SET_USER_NOTIFICATIONS } from '../../actionTypes';

const initialState = {
  userData: undefined,
  captchaData: undefined,
  userInfo: undefined,
  betLimitData: undefined,
  balanceLogData: undefined,
};

const memberReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS:
      return {
        ...state, userNotifications: action.value
      };
    case SET_USER_INFO:
      return {
        ...state, userInfo: action.value
      };
    case SET_USER_DATA:
      return {
        ...state, userData: action.value
      };
    case SET_CAPTCHA_DATA:
      return {
        ...state, captchaData: action.value
      };
    case SET_BET_LIMIT:
      return {
        ...state, betLimitData: action.value
      };
    case SET_BALANCE_LOG:
      return {
        ...state, balanceLogData: action.value
      };
    default:
      return state;
  }
};

export default memberReducers;