import { StatusCodes } from 'http-status-codes';

import { SET_BALANCE_LOG, SET_BET_LIMIT, SET_CAPTCHA_DATA, SET_MODAL_ALERT, SET_USER_DATA, SET_USER_INFO, SET_USER_NOTIFICATIONS, USER_LOGOUT } from '../../actionTypes';
import { apiService } from '../../../library';
import appActions from '../appActions';
import { store } from '../..';

const setUserData = appData => ({
  type: SET_USER_DATA,
  value: appData,
});

const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  value: userInfo,
});

const setCaptchaData = captchaData => ({
  type: SET_CAPTCHA_DATA,
  value: captchaData,
});

const setUserLogout = () => ({
  type: USER_LOGOUT,
});

const setBetLimit = betLimitData => ({
  type: SET_BET_LIMIT,
  value: betLimitData,
});

const setUserNotifications = userNotifications => ({
  type: SET_USER_NOTIFICATIONS,
  value: userNotifications,
});

const setBalanceLog = balanceLogData => ({
  type: SET_BALANCE_LOG,
  value: balanceLogData,
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const updateBetLimit = betLimitData => dispatch => {
  dispatch(setBetLimit(betLimitData));
};

const updateBalanceLog = balanceLogData => dispatch => {
  dispatch(setBalanceLog(balanceLogData));
};

const getInfo = successCallBack => dispatch => {
  const storeState = store.getState();
  const { oddsType } = storeState.appReducers || {};
  const { lotteryType } = storeState.gameReducers || {};
  apiService.get('/member/getInfo', new URLSearchParams({
    lotteryType: lotteryType?.item || 'HK6'
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallBack) {
        successCallBack();
      }
      if (data.result?.panKou.length > 0 && (!oddsType || data.result?.panKou.indexOf(oddsType) === -1)) {
        dispatch(appActions.setCurrentPankou(data.result?.panKou.split('')[0]));
      }
      if (!data.result?.panKou) {
        dispatch(appActions.setCurrentPankou(''));
      }
      return dispatch(setUserInfo(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const login = (loginData, callback = () => {}) => dispatch => {
  apiService.post('/member/login', loginData).then(res => {
    dispatch(setModalAlert({
      visible: false,
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        callback(data.result);
        return dispatch(setUserData(data.result));
      }
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: data.msg,
          onConfirmationClick: () => dispatch(getCaptcha())
        }
      }));
    }
  }).catch(error => {
    if (error.response.status === 400 && error.response.data.state === 2017) {
      const account = error.response.data.msg?.replace('你已经用帐号：', '')?.replace('登陆, 如需登陆其它帐号，请登出后重试!', '');
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg,
          confirmText: '登出',
          onConfirmationClick: () => dispatch(logout(account))
        }
      }));
    }
    if (error?.response?.data) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg,
          onConfirmationClick: () => dispatch(getCaptcha())
        }
      }));
    }
  });
};

const getRegisterPhoneSMS = phoneNum => dispatch => {
  apiService.get(`/member/SendRegisterPhoneSMS/${phoneNum}/`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'info',
        message: data.msg
      }));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getCaptcha = () => dispatch => {
  apiService.get('/member/Captcha').then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setCaptchaData(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const register = (loginName, password, affCode, captchaCode, safeCode, safePhone) => dispatch => {
  apiService.post('/member/register', {
    loginName,
    password,
    affCode,
    captchaCode,
    safeCode,
    safePhone
  }).then(res => {
    dispatch(setModalAlert({
      visible: false,
    }));
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      if (data.state === 1) {
        dispatch(setModalAlert({
          visible: true,
          type: 'mobileReminder',
          data: {
            headerTitle: '注册成功!',
            message: '将登入与导回到首页',
            onConfirmationClick: () => dispatch(login(loginName, password)),
            showCancelButton: false,
          }
        }));
      }
    }
  }).catch(error => {
    dispatch(setModalAlert({
      visible: false,
    }));
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg
        }
      }));
    }
    Promise.reject(error);
  });
};

const getBetLimit = lotteryType => dispatch => {
  apiService.get(`/member/BetLimit/${lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(updateBetLimit(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getBalanceLog = (page = 1, row = 15) => dispatch => {
  apiService.get('/member/BalanceLog', new URLSearchParams({
    page, row
  })).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(updateBalanceLog(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const userNotifications = (page = 1, row = 15) => dispatch => {
  apiService.get(
    '/member/userMessages',
    new URLSearchParams({
      page, row
    })
  ).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setUserNotifications(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const messageDetail = messageId => dispatch => {
  apiService.get(`/member/messageDetail/${messageId}`).then(res => {
    const { status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(userNotifications());
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const submitFeedBack = (type, title, content) => dispatch => {
  apiService.post('/member/Feedback', {
    feedbackType: type,
    title,
    content
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.msg) {
      dispatch(setModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error?.response?.data?.msg,
        }
      }));
    }
    Promise.reject(error);
  });
};

const changePassword = (oldPassword, newPassword, cfmPassword, successCallback) => dispatch => {
  apiService.post('/member/ChangePassword', {
    oldPassword,
    newPassword,
    cfmPassword
  }).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      if (successCallback) successCallback();
      return dispatch(setModalAlert({
        visible: true,
        type: 'success',
        data: {
          message: data.msg,
        }
      }));
    }
  }).catch(error => {
    if (error?.response?.data?.msg) {
      dispatch(setModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error?.response?.data?.msg,
        }
      }));
    }
    Promise.reject(error);
  });
};

const logout = (loginId, callback = () => {}) => dispatch => {
  if (loginId) {
    apiService.post('/member/Logout', {
      loginId,
      refreshToken: '',
    }).then(res => {
      const { status } = res || {};
      if (status === StatusCodes.OK) {
        callback();
        return dispatch(setUserLogout());
      }
    }).catch(error => {
      Promise.reject(error);
    });
  } else {
    return dispatch(setUserLogout());
  }
};

export default {
  getInfo,
  login,
  getRegisterPhoneSMS,
  getCaptcha,
  register,
  logout,
  getBetLimit,
  getBalanceLog,
  userNotifications,
  messageDetail,
  submitFeedBack,
  changePassword,
  setUserLogout,
  setUserData
};