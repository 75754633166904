import { StatusCodes } from 'http-status-codes';

import { APPEND_CURRENT_BETS, RESET_CURRENT_BETS, SET_COUNT_DOWN_TIMER, SET_CURRENT_BETS, SET_GAME_INFO, SET_GAME_ODDS, SET_MODAL_ALERT } from '../../actionTypes';
import { apiService } from '../../../library';
import memberActions from '../memberActions';
import { store } from '../..';

import appActions from '../appActions';

const setGameInfo = gameOdds => ({
  type: SET_GAME_INFO,
  value: gameOdds,
});

const setCountDownTimer = countDownTimer => ({
  type: SET_COUNT_DOWN_TIMER,
  value: countDownTimer,
});

const setGameOdds = odds => ({
  type: SET_GAME_ODDS,
  value: odds,
});

const setCurrentBets = bets => ({
  type: SET_CURRENT_BETS,
  value: bets
});

const appendCurrentBets = bets => ({
  type: APPEND_CURRENT_BETS,
  value: bets
});

const resetCurrentBets = () => ({
  type: RESET_CURRENT_BETS
});

const setModalAlert = modal => ({
  type: SET_MODAL_ALERT,
  value: modal
});

const confirmBets = (betItems, lotteryType, gameType, callback = () => {}) => dispatch => {
  apiService.post('/placeBet/confirmGameBet', {
    betData: betItems,
    lotteryType,
    gameType
  }).then(res => {
    const { data, status } = res || {};
    if (status === StatusCodes.OK) {
      const { betList, succeed, msg } = data.result || {};
      if (succeed !== 1) {
        return dispatch(setModalAlert({
          visible: true,
          type: 'error',
          data: {
            message: msg
          }
        }));
      }
      callback();
      const storeState = store.getState();
      const { platform } = storeState.appReducers || {};
      if (platform === 'Mobile') {
        dispatch(setModalAlert({
          visible: true,
          type: 'mobileBetsList',
          data: {
            headerClassName: 'text-center',
            list: betList
          }
        }));
      } else {
        dispatch(appendCurrentBets(betList));
      }
      return dispatch(memberActions.getInfo());
    }
  }).catch(error => {
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg,
        }
      }));
    }
    Promise.reject(error);
  });
};

const getCurrentBets = lotteryType => dispatch => {
  apiService.get(`/placeBet/currentBets/${lotteryType}`).then(res => {
    const { data, status } = res || {};

    if (status === StatusCodes.OK) {
      dispatch(setCurrentBets(data.result));
    }
  }).catch(error => {
    Promise.reject(error);
  });
};

const getGameOdds = (lotteryType, oddsType, isRefresh = false, iType = -1, isTmb, settingCode = '', midCode = '') => dispatch => {
  apiService.get(`/placeBet/gamesOdds/${lotteryType}`, new URLSearchParams({
    oddsType,
    iType,
    isTmb,
    settingCode,
    midCode,
    isRefresh
  })).then(res => {
    const { data, status } = res || {};
    if (res && status === StatusCodes.OK) {
      const originalData = data?.result;
      if (originalData?.state === 1) {
        const { oddsList } = originalData;
        let odds;
        if (oddsList) {
          odds = oddsList.reduce((obj, item) => {
            obj[item.keyCode] = item;
            return obj;
          }, {});
        }

        const tempResult = {
          isRefresh,
          ...data.result,
          oddsList: odds
        };

        return dispatch(setGameInfo(tempResult));
      }
      if (originalData?.state === 2 || originalData?.state === 3) {
        const tempResult = {
          isRefresh,
          ...data.result,
        };
        return dispatch(setGameInfo(tempResult));
      }
      return dispatch(setGameInfo({}));
    }
  }).catch(error => {
    if (error?.response?.data) {
      dispatch(appActions.updateModalAlert({
        visible: true,
        type: 'error',
        data: {
          message: error.response.data.msg,
        }
      }));
    }
    Promise.reject(error);
  });
};

const updateGameInfo = gameInfo => dispatch => (
  dispatch(setGameInfo(gameInfo))
);

const updateCountDownTimer = countDownTimer => dispatch => (
  dispatch(setCountDownTimer(countDownTimer))
);

const updateGameOdds = odds => dispatch => (
  dispatch(setGameOdds(odds))
);

const updateCurrentBets = () => dispatch => (
  dispatch(resetCurrentBets())
);

export default {
  confirmBets,
  getCurrentBets,
  getGameOdds,
  updateGameInfo,
  updateCountDownTimer,
  updateGameOdds,
  updateCurrentBets,
};