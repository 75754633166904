import { DELETE_HENEI_IN_CART, DELETE_ITEM_IN_CART, INSERT_HENEI_TO_CART, INSERT_ITEM_TO_CART, RESET_ITEM_IN_CART, UPDATE_ENTIRE_CART } from '../../actionTypes';

const insertItemToCart = companyInfo => ({
  type: INSERT_ITEM_TO_CART,
  value: companyInfo,
});

const insertHeNeiToCart = (keyCode, item) => ({
  type: INSERT_HENEI_TO_CART,
  value: {
    keyCode, item
  },
});

const deleteItemInCart = companyInfo => ({
  type: DELETE_ITEM_IN_CART,
  value: companyInfo,
});

const deleteHeNeiInCart = (keyCode, itemIndex) => ({
  type: DELETE_HENEI_IN_CART,
  payload: {
    keyCode, itemIndex
  },
});

const resetCart = () => ({
  type: RESET_ITEM_IN_CART,
});

const updateEntireCart = cart => ({
  type: UPDATE_ENTIRE_CART,
  value: cart
});

const updateCart = cart => dispatch => (
  dispatch(updateEntireCart(cart))
);

const addItemToCart = (item, reset) => dispatch => {
  if (reset) {
    dispatch(resetCart());
  }
  dispatch(insertItemToCart(item));
};

const addHeNeiToCart = (item, reset) => dispatch => {
  if (reset) {
    dispatch(resetCart());
  }
  dispatch(insertHeNeiToCart(item.keyCode, item));
};

const removeItemInCart = item => dispatch => (
  dispatch(deleteItemInCart(item))
);

const removeHeneiInCart = (item, itemIndex) => dispatch => (
  dispatch(deleteHeNeiInCart(item, itemIndex))
);

const resetItemInCart = () => dispatch => (
  dispatch(resetCart())
);

export default {
  addItemToCart,
  removeItemInCart,
  resetItemInCart,
  updateCart,
  addHeNeiToCart,
  removeHeneiInCart
};