import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { appActions, topupActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  cancelTopUp: transferId => dispatch(topupActions.cancel(transferId)),
});

function TransactionRecordDetailModal({ updateModalAlert, cancelTopUp, data, onCancelClick }) {
  const [_transactionData, setTransactionData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(_transactionData)) {
      setTransactionData(data);
    }
  }, [data]);

  return (
    <div className="transactionRecordModalWrapper">
      <Row className="modalHeader w-100">
        <Col xs="4" />
        <Col
          className="d-flex justify-content-center align-items-center"
          xs="4"
        >
          {t('TRANSACTION_DETAILS')}
        </Col>
        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <IoClose
            onClick={() => {
              updateModalAlert({
                visible: false
              });
            }}
            className="h4 mb-0 float-right closeIcon"
          />
        </Col>
      </Row>
      <Row className="modalContent p-3">
        <div className="p-0 item w-100">
          <table cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td>{t('ORDER_ID')}</td>
                <td>{_transactionData && _transactionData.order}</td>
              </tr>
              <tr>
                <td>{t('USERNAME')}</td>
                <td>{_transactionData && _transactionData.memberName}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_TYPE')}</td>
                <td>{_transactionData && _transactionData.transferType}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_TIME')}</td>
                <td>{_transactionData && moment.unix(_transactionData.time).format('YY-MM-DD HH:mm')}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_AMOUNT')}</td>
                <td>{_transactionData && _transactionData.transferAmount}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_PROCESSING_FEE')}</td>
                <td>{_transactionData && `${_transactionData.fee || 0} | ${_transactionData.feeThird || 0}`}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_STATUS')}</td>
                <td className={`${data.statusName === t('SUCCESS') ? 'successText' : data.statusName === t('FAIL') ? 'failText' : ''}`}>{_transactionData && _transactionData.statusName}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_DETAIL')}</td>
                <td>{_transactionData && _transactionData.content}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_REMARK')}</td>
                <td>{_transactionData && _transactionData.remarks}</td>
              </tr>
              <tr>
                <td>{t('TRANSACTION_APPROVAL_CODE')}</td>
                <td>{_transactionData && _transactionData.transferCode}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Row>
      <Row className="modalFooter p-2">
        <Col xs="12" className="justify-content-center d-flex">
          <Button
            type="button"
            className="closeBtn"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CLOSE')}
          </Button>
          {
            _transactionData && _transactionData.transferTypeCode === 100 && _transactionData.statusCode !== 500 && (
            <Button
              type="button"
              className="closeBtn ml-3"
              onClick={() => {
                cancelTopUp(_transactionData.id);
                if (onCancelClick) onCancelClick();
                updateModalAlert({
                  visible: false
                });
              }}
            >
              {t('CANCEL_TOP_UP_ORDER')}
            </Button>
            )
          }
        </Col>
      </Row>
    </div>
  );
}
export default connect(null, mapDispatchToProps)(TransactionRecordDetailModal);