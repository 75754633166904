import { SET_BET_RESULT_LIST, SET_LATEST_BET_RESULT_LIST, SET_LENG_RE_RESULT } from '../../actionTypes';

const initialState = {
  resultBetList: undefined,
  latestResultList: undefined,
  lengReResult: undefined,
};

const resultHistoryReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LENG_RE_RESULT:
      return {
        ...state, lengReResult: action.value
      };

    case SET_BET_RESULT_LIST:
      return {
        ...state, resultBetList: action.value
      };

    case SET_LATEST_BET_RESULT_LIST:
      return {
        ...state, latestResultList: action.value
      };

    default:
      return state;
  }
};

export default resultHistoryReducers;