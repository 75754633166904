import { Button } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions } from '../../../redux/actions';
import './styles.scss';

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
});

function GameRuleModal({ updateModalAlert, data }) {
  const { t } = useTranslation();
  return (
    <div className="gameRuleModalWrapper">
      <div className="modalHeader d-flex mx-0">
        <div className=" d-flex align-items-center col-6">
          {t('LOTTERY_RULES_RULES_EXPLANATION')}
        </div>
        <div className="d-flex justify-content-end align-items-center col-6">
          <IoClose
            className="h4 mb-0 float-right closeIcon"
            onClick={() => {
              updateModalAlert({
                visible: false
              });
              if (data && data.onConfirmationClick) data.onConfirmationClick();
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-start my-4 modalContent">
        {
          Array.isArray(data.rules) && data.rules.map((rule, index) => (
            <div
              key={`rule_${index.toString()}`}
              className="mb-1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: rule
              }}
            />
          ))
        }
      </div>
      <div className="modalFooter d-flex align-items-center mx-0">
        <Button
          className="confirmButton my-3"
          onClick={() => {
            updateModalAlert({
              visible: false
            });
            if (data && data.onConfirmationClick) data.onConfirmationClick();
          }}
        >
          {(data && data.confirmText) || t('CONFIRM')}
        </Button>
      </div>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(GameRuleModal);